import React from 'react';
import styles from './styles.module.css';
import { useSpring, animated } from 'react-spring';
import RaiseHandIcon from '../../../../../../icon';

const RaiseHandItem = ({ first_name, last_name, team_name }) => {
    const props = useSpring({
        to: { opacity: 0, transform: "translateY(-100%)" },
        from: { opacity: 1, transform: "translateY(0%)" },
        config: { duration: 300 },
        delay: 10000,
    });

    return (
        <animated.div style={props} className={styles.raise_hand}>
            <RaiseHandIcon className={styles.icon} icon="raise-hand-emoji" />
            {team_name && <p className={styles.team_name}>{`${team_name}`}</p>}
            <p className={`${styles.name} ${!team_name && styles.no_team}`}>{`${first_name} ${last_name}`}</p>
        </animated.div>
    );
};

export default React.memo(RaiseHandItem);