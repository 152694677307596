import React, { useEffect, useRef, useState } from 'react';

let clipId = 0;
const getUniqueClipId = () => clipId++;

const initializeAnalyser = (stream) => {
    const audioContext = new AudioContext();
    const audioSource = audioContext.createMediaStreamSource(stream);
    const analyser = audioContext.createAnalyser();
    analyser.smoothingTimeConstant = 0.2;
    analyser.fftSize = 256;
    audioSource.connect(analyser);
    return analyser;
}

const AudioLevelIndicator = ({ audioTrack, isAudioPlaying }) => {
    const SVGRectRef = useRef(null);
    const [analyser, setAnalyser] = useState();
    const mediaStreamTrack = audioTrack?.mediaStreamTrack;
    const clipPathId = `audio-level-clip-${getUniqueClipId()}`;

    useEffect(() => {
        if (audioTrack && mediaStreamTrack && isAudioPlaying) {
            let newMediaStream = new MediaStream([mediaStreamTrack.clone()]);
            const stopAllMediaStreamTracks = () => newMediaStream.getTracks().forEach(track => track.stop());
            audioTrack.on('stopped', stopAllMediaStreamTracks);
            const reinitializeAnalyser = () => {
                stopAllMediaStreamTracks();
                newMediaStream = new MediaStream([mediaStreamTrack.clone()]);
                setAnalyser(initializeAnalyser(newMediaStream));
            };
            setAnalyser(initializeAnalyser(newMediaStream));
            window.addEventListener('focus', reinitializeAnalyser);
            return () => {
                stopAllMediaStreamTracks();
                window.removeEventListener('focus', reinitializeAnalyser);
                audioTrack.off('stopped', stopAllMediaStreamTracks);
            };
        }
    }, [isAudioPlaying, mediaStreamTrack, audioTrack]);

    useEffect(() => {
        const SVGClipElement = SVGRectRef.current;
        if (isAudioPlaying && SVGClipElement && analyser) {
            const sampleArray = new Uint8Array(analyser.frequencyBinCount);

            const interval = setInterval(() => {
                analyser.getByteFrequencyData(sampleArray);
                let values = 0;
                const length = sampleArray.length;
                for (let i = 0; i < length; i++) values += sampleArray[i];
                const volume = Math.min(14, Math.max(0, Math.log10(values / length / 3) * 7));
                SVGClipElement.setAttribute('y', String(11 - volume));
            }, 100);

            return () => {
                SVGClipElement.setAttribute('y', '14');
                clearInterval(interval);
            };
        }
    }, [isAudioPlaying, analyser]);

    if (isAudioPlaying) {
        return (
            <svg width="13" height="17" viewBox="0 0 13 17">
                <defs>
                    <clipPath id={clipPathId}>
                        <rect ref={SVGRectRef} x="0" y="14" width="30" height="30" />
                    </clipPath>
                </defs>
                <rect clipPath={`url(#${clipPathId})`} width="5.2" height="10" x="4" y="0" rx="6" ry="3" fill="#23BF6E" />
                <path d="M12.2459 7.19264C12.5219 7.19264 12.7459 7.41664 12.7459 7.69264C12.7459 10.8066 10.3499 13.3626 7.30093 13.6156V16.2476C7.30093 16.5236 7.07793 16.7476 6.80093 16.7476C6.55593 16.7476 6.35093 16.5706 6.30993 16.3376L6.30093 16.2476V13.6156C3.25193 13.3616 0.856934 10.8066 0.856934 7.69264C0.856934 7.41664 1.08093 7.19264 1.35693 7.19264C1.63293 7.19264 1.85693 7.41664 1.85693 7.69264C1.85693 10.4226 4.07093 12.6366 6.80093 12.6366C9.53193 12.6366 11.7459 10.4226 11.7459 7.69264C11.7459 7.41664 11.9699 7.19264 12.2459 7.19264V7.19264ZM6.80093 0.525642C8.36693 0.525642 9.63493 1.79364 9.63493 3.35864V7.69264C9.63493 9.25664 8.36693 10.5256 6.80093 10.5256C5.23693 10.5256 3.96793 9.25564 3.96793 7.69264V3.35864C3.96793 1.79364 5.23693 0.525642 6.80093 0.525642ZM6.80093 1.52564C5.78893 1.52564 4.96793 2.34564 4.96793 3.35864V7.69264C4.96793 8.70464 5.78993 9.52564 6.80093 9.52564C7.81393 9.52564 8.63493 8.70564 8.63493 7.69264V3.35864C8.63493 2.34564 7.81493 1.52564 6.80093 1.52564Z" fill="white" />
            </svg>
        )
    } else {
        return (
            <svg width="13" height="17" viewBox="0 0 13 17">
                <path d="M11.93 7.19264C12.206 7.19264 12.43 7.41664 12.43 7.69264C12.43 10.8066 10.034 13.3626 6.98502 13.6156V16.2476C6.98502 16.5236 6.76202 16.7476 6.48502 16.7476C6.24002 16.7476 6.03502 16.5706 5.99402 16.3376L5.98502 16.2476V13.6156C4.86902 13.5226 3.84002 13.1216 2.98502 12.4966L3.70202 11.7796C4.49502 12.3196 5.45302 12.6366 6.48502 12.6366C9.21602 12.6366 11.43 10.4226 11.43 7.69264C11.43 7.41664 11.654 7.19264 11.93 7.19264V7.19264ZM1.04102 7.19264C1.31702 7.19264 1.54102 7.41664 1.54102 7.69264C1.54102 8.66764 1.82302 9.57664 2.31102 10.3426L1.58902 11.0636C0.929016 10.1056 0.541016 8.94364 0.541016 7.69264C0.541016 7.41664 0.765016 7.19264 1.04102 7.19264ZM9.31802 6.16164V7.69164C9.31902 9.25764 8.05102 10.5256 6.48602 10.5256C6.04002 10.5256 5.61802 10.4226 5.24302 10.2386L6.01902 9.46564C6.16802 9.50464 6.32502 9.52564 6.48602 9.52564C7.44902 9.52564 8.23702 8.78564 8.31402 7.84264L8.32002 7.69264V7.16164L9.32002 6.16164H9.31802ZM6.48502 0.525642C8.05102 0.525642 9.31902 1.79364 9.31902 3.35864L9.31702 3.33364L8.31802 4.33264V3.35864C8.31802 2.39664 7.57802 1.60864 6.63602 1.53164L6.48602 1.52564C5.52402 1.52564 4.73502 2.26564 4.65802 3.20864L4.65202 3.35864V7.69264C4.65202 7.78964 4.66002 7.88464 4.67402 7.97764L3.87002 8.78264C3.75802 8.51364 3.68602 8.22464 3.66102 7.92264L3.65202 7.69264V3.35864C3.65202 1.79364 4.92102 0.525642 6.48502 0.525642Z" fill="#FF0000" />
                <path d="M11.6491 1.82738C11.8284 1.653 12.1175 1.653 12.2967 1.82738C12.455 1.98299 12.4723 2.22355 12.3489 2.39794L12.2967 2.46054L1.32093 13.192C1.14166 13.3664 0.852628 13.3664 0.673357 13.192C0.515122 13.0364 0.497744 12.7958 0.621222 12.6215L0.673357 12.5589L11.6491 1.82738V1.82738Z" fill="#FF0000" />
            </svg>
        )
    }
}

export default React.memo(AudioLevelIndicator);