import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';

const usePlayRemoteTrack = (Track, ref, identity) => {
    const dispatch = useDispatch();
    const [isPlaying, setPlaying] = useState(false);
    const [isMuted, setMuted] = useState(Track?.isMuted);
    const isPlayable = !isMuted &&
        Track?.track &&
        Track?.isStarted &&
        Track?.isEnabled &&
        Track?.isSubscribed &&
        !Track?.isSwitchedOff &&
        Track?.readyState === 'live';

    useEffect(() => {
        if ('track' in Track) {
            const mediaTrack = Track.track.mediaStreamTrack
            const onunmute = (e) => {
                console.log(`[${identity}] | usePlayRemoteTrack | onTrackUnmute`, e.target);
                setMuted(false);
            }
            mediaTrack.addEventListener('unmute', onunmute);
            return () => {
                mediaTrack.removeEventListener('unmute', onunmute);
            }
        }
    }, [Track, identity]);

    useEffect(() => {
        if ('track' in Track && isPlayable) {
            // return a HTMLMediaElement
            const mediaElem = Track.track.attach(ref.current);
            mediaElem.play().then(() => setPlaying(true))
                .catch((error) => {
                    dispatch({ type: "ALLOWED-TO-PLAY", payload: false });
                    console.error(`[${identity}] | usePlayRemoteTrack | handlePlay:`, Track);
                    console.error(`[${identity}] | usePlayRemoteTrack | handlePlay:`, error?.name);
                    console.error(`[${identity}] | usePlayRemoteTrack | handlePlay:`, error?.message);
                });
            console.log(`[${identity}] | RemoteParticipant | attached ${Track.track.kind} track`);
            return () => {
                setPlaying(false);
                Track.track.detach();
                console.log(`[${identity}] | RemoteParticipant | detached ${Track.track.kind} track`);
            };
        }
    }, [ref, Track, isPlayable, dispatch, identity]);

    return [isPlaying]
}

export default usePlayRemoteTrack;