import React, { useEffect } from 'react';
import styles from './styles.module.css'
import Icon from '../../icon';
import { useDispatch } from 'react-redux';

const Error = ({ message, index }) => {
    const dispatch = useDispatch()

    useEffect(() => {
        let timeOut = null
        clearTimeout(timeOut)
        timeOut = setTimeout(() => {
            dispatch({ type: "REMOVE-ERROR", payload: index })
        }, 5000)
        return () => {
            clearTimeout(timeOut)
        }
    }, [dispatch, index])

    const handleClose = () => {
        dispatch({ type: "REMOVE-ERROR", payload: index })
    }

    console.log('Error');
    return (
        <>
            { message}
            < button className={styles.close} onClick={handleClose}>
                <Icon className={styles.close_icon} icon="close" />
            </button>
        </>
    )
}

export default React.memo(Error);
