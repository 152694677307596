import axios from '../../axios'
import Video from 'shared-studios-twilio-video';

export const fetchParticipants = () => {
    return (dispatch) => {
        dispatch({ type: "FETCH-PARTICIPANTS", payload: axios.get("/user/profiles") })
    }
}

export const LowerHand = (email) => {
    return (dispatch) => {
        dispatch({ type: 'LOWER-HAND', payload: axios.patch(`/user/lowerhand/${email}`) })
    }
}

export const manageParticipants = (data) => {
    return (dispatch) => {
        const config = { headers: { "Content-Type": "application/json" } }
        dispatch({ type: 'MANAGE-PARTICIPANTS', payload: axios.patch("room/participants", data, config) });
    }
}

export const manageParticipant = (email, data, raise_hand) => {
    return (dispatch) => {
        const config = { headers: { "Content-Type": "application/json" } }
        if (raise_hand) {
            dispatch({
                type: 'MANAGE-PARTICIPANT', payload: axios.patch(`/user/lowerhand/${email}`).then((res) => {
                    dispatch({ type: 'LOWER-HAND_FULFILLED', payload: res })
                    return axios.patch(`room/participants/${email}`, data, config);
                })
            })
        } else {
            dispatch({ type: 'MANAGE-PARTICIPANT', payload: axios.patch(`room/participants/${email}`, data, config) });
        }
    }
}

export const toggleParticipant = (email, raise_hand) => {
    return (dispatch) => {
        if (raise_hand) {
            dispatch({
                type: 'TOGGLE-PARTICIPANT', payload: axios.patch(`/user/lowerhand/${email}`).then((res) => {
                    dispatch({ type: 'LOWER-HAND_FULFILLED', payload: res })
                    return axios.patch(`room/toggleparticipant/${email}`)
                })
            })
        } else {
            dispatch({ type: 'TOGGLE-PARTICIPANT', payload: axios.patch(`room/toggleparticipant/${email}`) });
        }
    }
}

export const joinRoom = async () => {
    try {
        const { data: { token } } = await axios.get("/room/join");
        const room = await Video.connect(token, {
            audio: false,
            video: false,
            // logLevel: 'debug',
            bandwidthProfile: {
                video: {
                    mode: 'grid',
                    trackSwitchOffMode: 'disabled',
                    maxSubscriptionBitrate: 0,
                    maxTracks: 0,
                    renderDimensions: {
                        high: { width: 1080, height: 720 },
                        standard: { width: 640, height: 480 },
                        low: { width: 320, height: 240 }
                    }
                }
            },
            maxAudioBitrate: 16000,
            preferredVideoCodecs: [{ codec: 'VP8', simulcast: false }],
            networkQuality: { local: 1, remote: 1 }
        });
        // .then((room)=>{
        //     room.localParticipant.unpublishTrack
        // })
        return { room }
    } catch (error) {
        console.log("FAILED-TO-JOIN-ROOM:", error)
        return { error: error?.response?.data?.message || error.message }
    }
}

