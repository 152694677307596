import React, { useState } from 'react';
import styles from './styles.module.css';
import Icon from '../../../../../icon';
import ScreenShareMenu from './screen-share-menu';

const ScreenShareControl = ({ handleScreenSharing, handleCreateGrid }) => {
    const [open, toggle] = useState(false);

    return (
        <div className={styles.screen_share_control}>
            {open && <ScreenShareMenu {...{ handleScreenSharing, handleCreateGrid, toggle }} />}
            <button className={styles.screen_share_button} onClick={() => toggle(!open)}>
                <Icon className={styles.icon} icon="share-screen" />
            </button>
        </div>
    )
}

export default React.memo(ScreenShareControl);