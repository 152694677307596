import React from 'react';
import styles from './styles.module.css';
import RaiseHandIcon from '../../../../../../icon';

const RaiseHandItem = ({ first_name, last_name, team_name }) => {
    return (
        <div className={styles.raise_hand}>
            <RaiseHandIcon className={styles.icon} icon="raise-hand-emoji" />
            {team_name && <p className={styles.team_name}>{`${team_name}`}</p>}
            <p className={`${styles.name} ${!team_name && styles.no_team}`}>{`${first_name} ${last_name}`}</p>
        </div>
    );
};

export default React.memo(RaiseHandItem);