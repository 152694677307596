import { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { joinRoom, fetchParticipants } from '../components/actions/meeting';

const useCreateRoom = (meeting_type) => {
    const dispatch = useDispatch();
    const [state, setState] = useState({ room: null, joining: false, errorMessage: null });
    const { loading, active } = useSelector(({ meeting, user }) => {
        return {
            active: user.active,
            loading: meeting.loading
        }
    }, shallowEqual);

    useEffect(() => {
        if (active && meeting_type) {
            dispatch(fetchParticipants());
            setState({ room: null, joining: true, errorMessage: null });
            joinRoom().then(({ room, error }) => {
                if (!error) {
                    setState({ room, joining: false, errorMessage: null });
                    console.log('useBeforeUnload | addEventListener');
                } else {
                    setState({ room: null, joining: true, errorMessage: error });
                }
            })
            return () => {
                setState({ room: null, joining: false, errorMessage: null });
                console.log("useCreateRoom | unmounting:");
            }
        } if (!active) {
            setState({ room: null, joining: true, errorMessage: "Event has been deactivated." });
        }
    }, [dispatch, meeting_type, active]);

    useEffect(() => {
        const onBeforeUnload = (e) => {
            e.preventDefault();
            e.returnValue = '';
        }
        const onUnload = () => {
            state.room.disconnect();
        };
        if (state.room) {
            window.addEventListener("unload", onUnload);
            window.addEventListener("beforeunload", onBeforeUnload);
        }
        return () => {
            window.removeEventListener("unload", onUnload);
            window.removeEventListener("beforeunload", onBeforeUnload);
        }
    }, [state])

    return { ...state, joining: (loading || state.joining) }
}

export default useCreateRoom;