import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { fetchPreferences } from '../../../../actions/teams'
import LeaderPolling from "./leader_polling"
import NonLeaderPolling from "./non_leader_polling"

const Polling = ({ leader, submitted_preference, submitted, event_time, polling_countdown_time }) => {
    const dispatch = useDispatch();
    const preferences = useSelector(({ teams }) => teams.preferences, shallowEqual);

    useEffect(() => dispatch(fetchPreferences()), [dispatch])

    if (leader) {
        return <LeaderPolling {...{ preferences, submitted_preference, submitted, event_time, polling_countdown_time }} />
    } else {
        return <NonLeaderPolling  {...{ preferences, event_time, submitted, polling_countdown_time }} />
    }
}

export default React.memo(Polling)
