export default (state = { items: [], move: 0, stage: 0 }, { type, payload }) => {
    switch (type) {
        case "GET_AGENDAS_FULFILLED": {
            const agendas = payload.data.sort((a, b) => {
                const moveSort = a.move - b.move
                if (moveSort) return moveSort
                return a.stage - b.stage
            })
            const agendaId = getCurrentAgenda(agendas, state.move, state.stage)
            return Object.assign({}, state, {
                items: agendas.map((agenda) => {
                    return Object.assign({}, agenda, { isCurrent: (agenda.agenda_id === agendaId) })
                })
            })
        }
        case "FETCH-MOVES_FULFILLED": {
            const [move, stage] = payload.data.current
            const agendaId = getCurrentAgenda(state.items, move, stage)
            return Object.assign({}, {
                move, stage,
                items: state.items.map((agenda) => {
                    return Object.assign({}, agenda, { isCurrent: (agenda.agenda_id === agendaId) })
                })
            })
        }
        case "CURRENT-MOVE": {
            const [move, stage] = payload.current
            const agendaId = getCurrentAgenda(state.items, move, stage)
            return Object.assign({}, {
                move, stage,
                items: state.items.map((agenda) => {
                    return Object.assign({}, agenda, { isCurrent: (agenda.agenda_id === agendaId) })
                })
            })
        }
        default: {
            return state
        }
    }
}

const getCurrentAgenda = (agendas, _move, _stage) => {
    const current = agendas.find(({ move, stage }) => move === _move && stage === _stage)
    if (current) return current.agenda_id
    const allPastAgendas = agendas.filter(({ move, stage }) => move < _move || (move === _move && stage <= _stage))
    if (allPastAgendas.length) return allPastAgendas[allPastAgendas.length - 1].agenda_id
    return null
}