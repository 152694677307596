import React, { useState } from "react";
import styles from "./styles.module.css";
import Avatar from "../../avatar";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

const UserAvatar = ({ name, profile, team_name }) => {
    const dispatch = useDispatch();
    const { event_id } = useParams();
    const [open, setOpen] = useState(false);

    const handleLogout = () => {
        dispatch({ type: "LOGGED-OUT", payload: event_id })
    }

    return (
        <div className={styles.user_avatar} onClick={() => setOpen((open) => !open)} >
            <Avatar className={styles.avatar} picture={profile} />
            <div className={styles.dropdown}>
                <ul>
                    <li className={`${open && styles.open}`}>
                        {open && (
                            <ul>
                                <li>
                                    <div>
                                        <p className={styles.represent_country}>{name}</p>
                                        <p className={styles.team_name}>{team_name}</p>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <button className={styles.logout_button} onClick={handleLogout}>
                                            Log out
                                        </button>
                                    </div>
                                </li>
                            </ul>
                        )}
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default React.memo(UserAvatar);
