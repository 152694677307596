import React from 'react';
import styles from './styles.module.css'

const Avatar = ({ className, picture }) => {
    return (
        <div name="picture" className={`${styles.avatar} ${className}`}>
            <img className={styles.picture} alt='' src={picture} />
        </div>
    )
}

export default React.memo(Avatar)