import { useRef } from 'react';

const useDrag = (roomEmpty) => {
    const elem = useRef();

    const handleDrag = (e) => {
        if (!roomEmpty) {
            let prevX = e.clientX
            let prevY = e.clientY

            const onMouseMove = (e) => {
                if (elem.current) {
                    let newX = prevX - e.clientX
                    let newY = prevY - e.clientY
                    const rect = elem.current.getBoundingClientRect()

                    elem.current.style.left = rect.left - newX + "px";
                    elem.current.style.top = rect.top - newY + "px";

                    prevX = e.clientX
                    prevY = e.clientY
                }
            }

            const onMouseUp = () => {
                window.removeEventListener('mouseup', onMouseUp);
                window.removeEventListener('mousemove', onMouseMove);
            }

            window.addEventListener('mouseup', onMouseUp);
            window.addEventListener('mousemove', onMouseMove);
        }
    }

    return { elem, handleDrag }
}

export default useDrag;