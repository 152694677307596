import React, { useEffect, useState } from 'react';
import Loading from '../loading'

const BrowserCheck = ({ children }) => {
    const [state, setState] = useState({ supported: false, loading: true })
    useEffect(() => {
        var browserName;
        var userAgent = navigator.userAgent;

        if (userAgent.indexOf("Opera") !== -1) {
            browserName = "Opera";
        } else if (userAgent.indexOf("MSIE") !== -1) {
            browserName = "Microsoft Internet Explorer";
        } else if (userAgent.indexOf("Chrome") !== -1) {
            browserName = "Chrome";
        } else if (userAgent.indexOf("Safari") !== -1) {
            browserName = "Safari";
        } else if (userAgent.indexOf("Firefox") !== -1) {
            browserName = "Firefox";
        } else {
            browserName = "The browser you are using";
        }

        if (browserName === "Chrome" || browserName === "Firefox") {
            setState({ supported: true, loading: false })
        } else {
            setState({ supported: false, loading: false, browser_name: browserName })
        }
    }, [setState]);

    if (state.loading) {
        return <Loading />
    } if (!state.supported) {
        return <Loading message={`${state.browser_name} is not currently supported. Please use Chrome or Firefox.`} />
    } else {
        return children
    }
}

export default React.memo(BrowserCheck);
