export default (state = [], { type, payload }) => {
  switch (type) {
    case "FETCH-RESOURCES_FULFILLED": {
      return payload.data.sort((a, b) => {
        const moveSort = a.move - b.move 
        if(moveSort) return moveSort
        return a.stage - b.stage
      })
    }
    default: {
      return state
    }
  }
}