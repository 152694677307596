import React, { useRef, useEffect } from 'react';
import styles from './styles.module.css';
import Vimeo from '@vimeo/player';
import { useSelector, shallowEqual } from "react-redux";
import useRatioHeight from '../../../../hooks/use-ratio-height';

const Meeting = () => {
    const iframe = useRef();
    const [elem, height] = useRatioHeight(16, 9, false, 0);
    const stream_url = useSelector(({ user }) => user.stream_url, shallowEqual);

    useEffect(() => {
        const player = new Vimeo(iframe.current)
        player.play();
        player.setVolume(0.5);
    }, [iframe]);

    return (
        <div ref={elem} className={styles.meeting_container} style={{ height }}>
            <iframe
                ref={iframe}
                className={styles.video_transition}
                title="live stream"
                src={stream_url}
                frameBorder="0"
                allow="autoplay"
                allowFullScreen={true}
            />
        </div>
    )
}

export default React.memo(Meeting);