import React from 'react';
import styles from './styles.module.css'
import Transitions from './transitions';
import TeamChat from './team-chat';
import Announcement from './announcement';
import HappeningNow from './happening-now';
import useFullScreen from '../../../hooks/use-full-screen';
import usePollingResults from '../../../hooks/use-polling-results';

const CentralPanel = () => {
    const [isFullScreen] = useFullScreen();
    const { title, description, announcement } = usePollingResults();

    console.log("CentralPanel");
    return (
        <div id="idOfMAP" className={`${styles.central_panel} ${styles[isFullScreen && 'fullScreen']}`}>
            <Announcement isFullScreen={isFullScreen} announcement={announcement} />
            <Transitions isFullScreen={isFullScreen} />
            <HappeningNow isFullScreen={isFullScreen} description={description} title={title} />
            <TeamChat isFullScreen={isFullScreen} />
        </div>
    )
}

export default React.memo(CentralPanel)