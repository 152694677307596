import store from '../redux/store';
import { LocalVideoTrack } from 'shared-studios-twilio-video';

const getGridViewTrack = (_width, _height) => {
    let draw = false;
    let unsubscribe = null;
    const width = _width || 1280;
    const height = _height || 720;
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = width;
    canvas.height = height;
    let columns, rows, drawHeight, drawWidth, items = [];

    const startDrawingLoop = () => {
        Promise.all(items.map((image) => image.grabFrame().catch(() => { })))
            .then((blobs) => {
                let total = blobs.length;
                for (let j = 0; j < rows; j++) {
                    const totalWidthOfLastRow = total * drawWidth;
                    const startX = (width - totalWidthOfLastRow) / 2;
                    for (let i = 0; i < columns; i++) {
                        if (total > 0) {
                            const blob = blobs[total - 1];
                            if (blob) {
                                const sw = ((blob.height / drawHeight) * drawWidth)
                                const sx = ((blob.width - ((blob.height / drawHeight) * drawWidth)) / 2)
                                if (j < rows - 1) {
                                    ctx.drawImage(blob, sx, 0, sw, blob.height, (i * drawWidth), (j * drawHeight), drawWidth, drawHeight);
                                } else {
                                    ctx.drawImage(blob, sx, 0, sw, blob.height, startX + i * drawWidth, j * drawHeight, drawWidth, drawHeight);
                                }
                            }
                            total--;
                        }
                    }
                }
            })
        if (draw) requestAnimationFrame(startDrawingLoop);
    }

    const getSize = (totalItem) => {
        let rows = 1;
        let columns = 1;
        while (totalItem > (columns * rows)) {
            if (columns === rows) columns++
            else rows++
        }
        return { columns, rows }
    };

    const stop = () => {
        draw = false;
        if (unsubscribe) unsubscribe();
    }

    const filterParticipants = ({ type, videoTrack, name }) => {
        return name && videoTrack.track && type !== "producer"
    }

    const mapParticipants = ({ videoTrack }) => {
        const track = videoTrack.track.mediaStreamTrack
        return new ImageCapture(track);
    }

    const getGridViewTrack = () => {
        const tracks = canvas.captureStream(30).getVideoTracks();
        return tracks.map((track) => {
            track = new LocalVideoTrack(track, { name: "grid-view" })
            track.stop = stop
            return track
        })
    }

    const updateConstraints = () => {
        const { meeting: { participants: ps } } = store.getState();
        items = Object.values(ps).filter(filterParticipants).map(mapParticipants);
        const length = items.length;
        ctx.fillStyle = "#000"
        ctx.fillRect(0, 0, width, height);
        const size = getSize(length);
        rows = size.rows;
        columns = size.columns;
        drawHeight = height / size.rows;
        drawWidth = width / size.columns;
    }

    const start = () => {
        draw = true
        startDrawingLoop();
        updateConstraints();
        unsubscribe = store.subscribe(updateConstraints);
        return getGridViewTrack();
    }



    return { start }
}

export default getGridViewTrack;