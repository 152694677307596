import React, { useLayoutEffect, useRef } from 'react';
import styles from './styles.module.css'
import RemoteParticipant from '../../remote-participant';

const ShareScreen = ({ remoteParticipants: rps, admin }) => {
    const elm = useRef()

    useLayoutEffect(() => {
        rps.forEach(({ audioTrack }) => {
            if ('track' in audioTrack) {
                elm.current.appendChild(audioTrack.track.attach())
            }
        })
        return () => {
            rps.forEach(({ audioTrack }) => {
                if ('track' in audioTrack) {
                    audioTrack.track.detach().forEach((e) => {
                        e.remove();
                    })
                }
            })
        }
    }, [rps]);

    console.log("ShareScreen");
    return (
        <div ref={elm} className={styles.screen_sharing}>
            <RemoteParticipant size={{ width: "100%", height: "100%" }} {...admin} />
        </div>
    )
}

export default React.memo(ShareScreen);