import moment from 'moment';

export default (state = { loading: true, message: null }, { type, payload }) => {
    switch (type) {
        case "FETCH-MOVES_PENDING": {
            return Object.assign({}, { loading: true });
        }
        case "FETCH-MOVES_FULFILLED": {
            return Object.assign({}, state, {
                loading: false,
                moves: payload.data.moves,
                current: getOffset(payload.data.current)
            });
        }
        case "FETCH-MOVES_REJECTED": {
            const message = payload?.response?.data?.message || payload?.message || 'Unknown error occurred while fetching the moves.'
            return Object.assign({}, { loading: false, message });
        }
        case "CURRENT-MOVE": {
            setOffset(payload)
            return Object.assign({}, state, { current: getOffset(payload.current) });
        }
        case "FETCH-CURRENT-MOVE_FULFILLED": {
            return Object.assign({}, state, { current: getOffset(payload.data.current) });
        }
        // admin
        case "UPDATE-MOVE_PENDING": {
            return Object.assign({}, state, { updating_move: true });
        }
        // admin
        case "UPDATE-MOVE_REJECTED": {
            console.log(payload.response.data);
            return Object.assign({}, state, { updating_move: false });
        }
        // admin
        case "UPDATE-MOVE_FULFILLED": {
            return Object.assign({}, state, { updating_move: false });
        }
        default: {
            return state;
        }
    }
}

const getOffset = (current) => {
    const event_time = current[3];
    if (event_time) {
        const offset = localStorage.getItem("event_time");
        if (offset) {
            const time = moment(event_time);
            current[3] = time.subtract(offset, 'seconds').toISOString();
        }
    }
    const polling_time = current[4];
    if (polling_time) {
        const offset = localStorage.getItem("polling_time");
        if (offset) {
            const time = moment(polling_time);
            current[4] = time.subtract(offset, 'seconds').toISOString();
        }
    }
    return current
}

const setOffset = ({ current, polling_time }) => {
    if (!polling_time) {
        const event_time = current[3];
        if (event_time) {
            const time = moment(event_time);
            const diff = time.diff(moment(), 'seconds');
            localStorage.setItem("event_time", diff);
        }
    } else {
        const polling_time = current[4];
        if (polling_time) {
            const time = moment(polling_time);
            const diff = time.diff(moment(), 'seconds');
            localStorage.setItem("polling_time", diff);
        }
    }
}