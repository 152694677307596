import { useSelector, shallowEqual } from "react-redux";

const usePollingResults = () => {
    const { sub_stage, move_id, team_0, team_1, user_type, stage, team_name, leader, hasResults } = useSelector(({ user, moves: list }) => {
        const { moves, current } = list
        const [move, stage, sub_stage] = current
        return {
            move_id: move,
            leader: user.leader,
            user_type: user.type,
            team_name: user.team_name,
            stage: moves[move][stage],
            team_0: user?.preference_result?.[`move_${move}`]?.[0],
            team_1: user?.preference_result?.[`move_${move}`]?.[1],
            sub_stage: moves[move][stage]["sub_stages"][sub_stage],
            hasResults: ("preference_result" in user && `move_${move}` in user.preference_result),
        }
    }, shallowEqual);

    const { current_team_to_team_meeting_id: meeting_id, meeting_type, transition_type, type, intra_team_meeting } = stage
    let { title, description, announcement, polling, generate_results } = sub_stage

    if ((meeting_type === "team" || (type === "transition" && meeting_id)) && user_type === "participant" && !team_name) {
        announcement = "you are not assigned to a team";
        title = "You are not assigned to a team";
        description = "";
    }
    // else if (type === "meeting" && meeting_type === "team" && user_type === "participant" && polling && intra_team_meeting) {
    //     // announcement = `MOVE ${move_id}: INTER TEAM MEETING`
    //     title = "Select the teams you would like to meet with"
    //     description = leader ? "YOU must make this selection. Your teammate can see this panel but not make choices. Click and drag the choices from the right into your priority order on the left. Final assignment will depend on the preferences of all teams."
    //         : "YOUR TEAMMATE must make this selection.Final assignment will depend on the preferences of all teams."
    // } 
    else if (type === "meeting" && meeting_type === "team" && generate_results && intra_team_meeting) {
        if (user_type === "participant") {
            // announcement = `MOVE ${move_id}: INTER TEAM MEETING`
            // title = "Please review your conference assignments below"
            // description = `You are meeting with ${team_0?.name} and ${team_1?.name}`
            description = `You now have 3 minutes to further coordinate your team's response to this move and prepare for your conferences with other teams.
            &lt;br&gt;You and your teammate will first meet with ${team_0?.name.toUpperCase()} for 8 minutes and then ${team_1?.name.toUpperCase()} for 8 minutes before returning to another one-on-one team meeting for 4 minutes before the plenary discussion.`
        }
        if (user_type === "expert" && (team_0.name || team_1.name)) {
            // announcement = `MOVE ${move_id}: INTER TEAM MEETING`
            // title = "Please review your conference assignments below"
            if (team_0.name && team_1.name) description = `You are meeting with ${team_0.name.toUpperCase()} and ${team_1.name.toUpperCase()} during the inter-team meeting.`
            if (team_0.name && !team_1.name) description = `For the first inter-team meeting you will be meeting with team ${team_0.name.toUpperCase()} and during the second inter-team meeting you and other event organizers will be having a private meeting.`
            if (!team_0.name && team_1.name) description = `During the first inter-team you and other event organizers will be having a private meeting and for the second inter-team meeting you will be meeting with team ${team_1.name.toUpperCase()}.`
        }
    }
    // else if (type === "meeting" && meeting_type === "team" && user_type === "participant" && intra_team_meeting) {
    //     // announcement = `MOVE ${move_id}: INTER TEAM MEETING`
    //     title = "Discuss the move with your team"
    //     description = "In a few minutes, you'll need to select the teams you'd like to meet with. During the game, a slide with the summary of this move will be shown here"
    // } 
    else if (type === "transition" && transition_type === "image-transition" && hasResults && meeting_id >= 0) {
        if (user_type === "participant") {
            console.log("meeting_id", meeting_id)
            const team = meeting_id === 0 ? team_0 : meeting_id === 1 ? team_1 : "undefined"
            // announcement = `Standby to meet with ${team.name}`
            title = `You are meeting next with ${team.name.toUpperCase()}`
            // description = ""
        }
        if (user_type === "expert" && (team_0.name || team_1.name)) {
            const team = meeting_id === 0 ? team_0 : meeting_id === 1 ? team_1 : "undefined"
            if (team.name) {
                // announcement = `Standby to meet with ${team.name}`
                title = `You are meeting next with ${team.name.toUpperCase()}`
                // description = ""
            }
        }
    } else if (meeting_type === "team_to_team" && hasResults && meeting_id >= 0) {
        if (user_type === "participant") {
            console.log("meeting_id", meeting_id)
            const team = meeting_id === 0 ? team_0 : meeting_id === 1 ? team_1 : "undefined"
            // announcement = `MOVE ${move_id}: Conference with ${team.name}`
            title = `You are meeting with ${team.name.toUpperCase()}`
            // description = ""
        }
        if (user_type === "expert" && (team_0.name || team_1.name)) {
            const team = meeting_id === 0 ? team_0 : meeting_id === 1 ? team_1 : "undefined"
            if (team.name) {
                // announcement = `MOVE ${move_id}: Conference with ${team.name}`
                title = `You are meeting with ${team.name.toUpperCase()}`
                // description = ""
            }
        }
    }
    return { title, description, announcement }
}

export default usePollingResults;
