import React, { useEffect } from 'react';
import Loading from '../loading';
import LoginPage from "../login-page";
import { Route } from "react-router-dom";
import { fetchUserProfile } from '../actions/user';
import { useDispatch, useSelector, shallowEqual } from "react-redux";

const Authentication = ({ component: Component, ...rest }) => {
    const dispatch = useDispatch();
    const { authenticated, logged_in, loading, event_id } = useSelector(({ user }) => {
        const { logged_in, authenticated, loading, event_id } = user
        return { logged_in, authenticated, loading, event_id }
    }, shallowEqual);

    useEffect(() => {
        const eventId = rest?.computedMatch?.params?.event_id
        if (!eventId) return alert("Invalid URL event id not found.");

        dispatch({ type: "SET-EVENT-ID", payload: rest.computedMatch.params.event_id });
        if (event_id && authenticated && loading) {
            const accessToken = localStorage.getItem(`${eventId}_access_token`);
            const refreshToken = localStorage.getItem(`${eventId}_refresh_token`);
            console.log({ accessToken, refreshToken, event_id })
            if (accessToken && refreshToken) {
                dispatch({ type: "AUTHENTICATED", payload: { refreshToken, accessToken } });
            } else {
                dispatch({ type: "UNAUTHORIZED" });
            }
        } else if (event_id && authenticated && !logged_in) {
            dispatch(fetchUserProfile());
        }
    }, [authenticated, event_id, logged_in, dispatch, loading, rest]);

    return (
        <Route {...rest} render={(props) => {
            console.log("Authentication");
            if (authenticated && (loading || !logged_in)) return <Loading />
            else if (!authenticated && !loading) return <LoginPage />
            else return <Component {...props} />
        }} />
    )
}
export default React.memo(Authentication);