import React, { useEffect, useRef, useState } from "react";
import styles from "./styles.module.css";
import Avatar from "../../landing-page/avatar";
import picture from './unnamed-avatar.jpg';

const Video = ({ videoTrack }) => {
    const videoRef = useRef(false);
    const [isVideoPlaying, setVideoPlaying] = useState(false);

    useEffect(() => {
        if (videoTrack) {
            const video = videoTrack.attach(videoRef.current);
            const onCanPlay = () => video.play();
            const onPlay = () => setVideoPlaying(true);
            video.addEventListener('play', onPlay);
            video.addEventListener('canplay', onCanPlay)
            return () => {
                videoTrack.stop();
                videoTrack.detach();
                setVideoPlaying(false);
                video.removeEventListener('play', onPlay);
                video.removeEventListener('canplay', onCanPlay)
            };
        }
    }, [videoRef, videoTrack]);

    return (
        <>
            {!isVideoPlaying && <Avatar className={styles.avatar} picture={picture} />}
            <video ref={videoRef} className={styles[isVideoPlaying ? 'video' : 'hide_video']} />
        </>
    )
};

export default React.memo(Video);
