import React from "react";
import Audio from './audio';
import Video from './video';
import Icon from '../../../../../icon';
import styles from "./styles.module.css";
import useDrag from "../../../../../../hooks/use-drag";
import ScreenShareControl from './screen-share-control';
import { useSelector, shallowEqual } from "react-redux";
import useGetDisplayTracks from "../../../../../../hooks/use-get-display-tracks";

const ProducerSelfieView = ({ room, roomEmpty, totalParticipants }) => {
    const lp = room.localParticipant;
    const { elem, handleDrag } = useDrag(roomEmpty);
    const [tracks, isLive, handleScreenSharing] = useGetDisplayTracks(lp);
    const { name, picture } = useSelector(({ user }) => {
        return {
            picture: user.profile_picture,
            name: user.first_name.concat(" ", user.last_name),
        };
    }, shallowEqual);

    console.log(`LocalParticipant | render [${name}]`);
    return (
        <div id="local-video" ref={elem} className={`${styles.local_video} ${roomEmpty ? styles.full : styles.normal}`} onMouseDown={handleDrag} onContextMenu={(e) => e.preventDefault()}>
            <Video {...{ videoTrack: tracks.video, name, picture }} />
            <Audio {...{ audioTrack: tracks.audio, name }} />
            <div className={styles.indicator_viewers} >
                <p className={`${styles.indicator} ${isLive ? styles.live : styles.offline}`}>{isLive ? "LIVE" : "OFFLINE"}</p>
                {roomEmpty && <div className={styles.viewers}><Icon icon="viewers" />{totalParticipants}</div>}
            </div>
            <ScreenShareControl {...{ handleScreenSharing }} />
        </div>
    );
};

export default React.memo(ProducerSelfieView);
