import React from 'react';
import styles from './styles.module.css';
import Icon from '../../../../../../icon';

const AudioControl = ({ audioTrack, handleAudio }) => {
    return (
        <button onClick={handleAudio}>
            <Icon className={styles.icon} icon={audioTrack ? "audio-unmute" : "audio-mute"} />
        </button>
    )
}

export default React.memo(AudioControl)