import React from "react";
import styles from "./styles.module.css";

const TestPage = () => {
    return (
        <div className={styles.page}>
            <p className={styles.four_o_four}>404</p>
            <p className={styles.title}>Page Error</p>
            <p className={styles.message}>Sorry, we can’t seem to find the page you’re looking for. It’s either unavailable or does not exist. </p>
        </div>
    );
};

export default React.memo(TestPage);
