import usePublishTrack from './use-publish-track';
import { createLocalAudioTrack } from 'shared-studios-twilio-video';
import { useState, useEffect, useCallback } from 'react';

const useGetAudioTrack = (lp, deviceId) => {
    // const isPublished = false;
    const [track, setTrack] = useState(null);
    const [isPublished] = usePublishTrack(lp, track, 'audio');

    const createTrack = useCallback(() => {
        console.log("useGetAudioTrack | createTrack| deviceId", deviceId);
        const constrains = {}
        if (deviceId) constrains.deviceId = { exact: deviceId }
        createLocalAudioTrack(constrains)
            .then((track) => {
                setTrack(track);
                console.log("useGetAudioTrack | createTrack", track);
            }).catch((error) => {
                alert(error.message);
                console.error("useGetAudioTrack | createTrack", error);
            });
    }, [setTrack, deviceId]);

    const handleTrack = () => {
        if (track) {
            track.stop();
            setTrack(null);
            console.log("useGetAudioTrack | handleTrack | stopped");
        } else {
            createTrack();
        }
    }

    useEffect(createTrack, [createTrack]);

    return [track, isPublished, handleTrack]
}

export default useGetAudioTrack;