import React from 'react';
import styles from './styles.module.css';
import { useSelector, shallowEqual } from 'react-redux';

const Announcement = () => {
    const { announcement } = useSelector(({ moves: { moves, current } }) => {
        const [move, stage, sub_stage_id] = current
        const sub_stages = moves[move][stage].sub_stages[sub_stage_id]
        return Object.assign(sub_stages, sub_stages.guest)
    }, shallowEqual);

    return (
        <div className={styles.announcement}>
            <p className={styles.title}>{announcement}</p>
        </div>
    )
}

export default React.memo(Announcement);