import axios from '../../axios'


export const getTeams = () => {
    return (dispatch) => {
        dispatch({ type: "GET_TEAMS", payload: axios.get("team") })
    }
}

export const fetchPreferences = () => {
    return (dispatch) => {
        dispatch({ type: "PREFERENCES", payload: axios.get(`user/preferences`) })
    }
}

export const submitPreference = (preference) => {
    return (dispatch) => {
        const filteredPreference = preference.filter(pref => !pref.blank)
        if (filteredPreference.length !== 0 ) {
            dispatch({ type: "SUBMIT_TEAM_PREFERENCE", payload: axios.patch('user/submit-preference',filteredPreference ) })
        }
        else {
            dispatch({ type: "SUBMIT_TEAM_PREFERENCE_REJECTED", payload: { message: "Please select teams." } })
        }
    }
}
