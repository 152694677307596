import React from "react"
import styles from './styles.module.css'
import Icon from "../icon"

const ErrorMessage = ({ error_message }) => {
    return (
        <div className={styles.error_message_container}>
            <Icon icon="error-2" />
            <div>{error_message}</div>
        </div>
    )
}

export default React.memo(ErrorMessage);