import React, { useState } from 'react';
import styles from './styles.module.css';
import User from './user';
import Icon from '../../../../../../icon';
import CloseControl from '../../close-control';
import useDrag from '../../../../../../../hooks/use-drag';
import { useSelector, shallowEqual } from 'react-redux';

const UserSearch = ({ handelClick }) => {
    const { elem, handleDrag } = useDrag(false);
    const [searchString, setSearchString] = useState("");
    const [searchType, setSearchType] = useState("name");
    const { users, loading } = useSelector(({ meeting }) => {
        return {
            users: Object.values(meeting.participants),
            loading: meeting.manage_participant_loading
        }
    }, shallowEqual);

    const handleSearch = ({ target }) => setSearchString(target.value);
    const handleSearchType = ({ target }) => setSearchType(target.value);

    const filter = ({ name, team_name }) => {
        if (searchString.length) {
            const re = new RegExp("^" + searchString, "i");
            if (searchType === "name") {
                const nameArr = name.split(" ");
                return name.match(re) || nameArr[0].match(re) || nameArr[nameArr.length - 1].match(re)
            } else {
                return team_name && team_name.match(re)
            }
        }
        return true
    }

    return (
        <div ref={elem} className={styles.search_bar} onMouseDown={handleDrag}>
            <CloseControl onClick={() => handelClick("search_bar")} />
            <select className={styles.select_input} value={searchType} onChange={handleSearchType}>
                <option value="name">Name</option>
                <option value="team">Team</option>
            </select>
            <input className={styles.search_input} placeholder="Search here..." value={searchString} onChange={handleSearch} />
            <div className={styles.search_results}>
                {users.filter(filter).map((user) => <User key={user.identity} {...user} />)}
            </div>
            {loading && <div className={styles.loading}><Icon className={styles.loading_icon} icon="loading" /></div>}
        </div>
    )
}

export default React.memo(UserSearch)