import React from 'react';
import styles from './styles.module.css'
import Icon from '../icon'

const TextInput = ({ className, error, ...rest }) => {
    return (
        <div className={`${className} ${error ? styles.error_input : styles.input}`}>
            <input className={`${error ? styles.error_input_field : styles.input_field}`} {...rest} />
            {error && <p className={styles.error_message}><Icon icon="error" /> {error}</p>}
        </div>
    );
}
export default React.memo(TextInput);