import React from 'react';
import styles from './styles.module.css'

const SubmitPreferenceButton = ({ submitTeamPreference, submitted, timeLeft }) => {

    const handleClick = () => {
        if (timeLeft && !submitted) {
            submitTeamPreference()
        }
    }

    return (
        <div className={`${styles.submit_button_leader} ${(!timeLeft || submitted) ? styles.submit_button_end : null}`} onClick={handleClick}>
            <span className={styles.submit_button_leader_text}>{submitted ? "Submitted" : !timeLeft ? "Time Elapsed" : "Submit"}</span>
            {(timeLeft && !submitted) && <p className={`${styles.submit_button_leader_time}`}>{timeLeft.format("hh:mm:ss", { trim: false })}</p>}
        </div>
    )
}

export default React.memo(SubmitPreferenceButton)