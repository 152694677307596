import React from 'react';
import styles from './styles.module.css';
import RaiseHand from './raise-hand';
import VideoControl from './video-control';
import AudioControl from './audio-control';
import DeviceControl from './device-control';

const MeetingControls = ({ roomEmpty, raise_hand, type, audioTrack, handleAudio, videoTrack, handleVideo, devices, selected, handleDeviceChange }) => {

    console.log("MeetingControls");
    return (
        <div className={`${styles.meeting_controls} ${styles[roomEmpty ? "button_big" : "button_small"]}`}>
            {(raise_hand && type === "participant") && <RaiseHand />}
            <AudioControl {...{ audioTrack, handleAudio }} />
            <VideoControl {...{ videoTrack, handleVideo }} />
            <DeviceControl {...{ roomEmpty, devices, selected, handleDeviceChange }} />
        </div>
    )
}

export default React.memo(MeetingControls);