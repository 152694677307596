export default (state = { list: [], preferences: [], loading: true, loading_message: null }, { type, payload }) => {
    switch (type) {
        case "GET_TEAMS_FULFILLED": {
            return Object.assign({}, state, { list: payload.data })
        } case "GET_TEAMS_REJECTED": {
            console.log(payload);
            return state;
        }
        case 'USER-STATUS': {
            return Object.assign({}, state, {
                list: state.list.map((user) => {
                    if (user.email === payload.email) {
                        return Object.assign({}, user, payload)
                    } else {
                        return user
                    }
                })
            });
        }
        case 'GUEST-CONNECTED': {
            return Object.assign({}, state, {
                list: state.list.map((item) => {
                    if (item.type === "guest") item.total_connected = item.total_connected + 1
                    return item
                })
            })
        }
        case 'GUEST-DISCONNECTED': {
            return Object.assign({}, state, {
                list: state.list.map((item) => {
                    if (item.type === "guest") item.total_connected = item.total_connected - 1
                    return item
                })
            })
        }
        case "PREFERENCES_FULFILLED": {
            return Object.assign({}, state, { preferences: payload.data, loading: false })
        }
        case "PREFERENCES_PENDING": {
            return Object.assign({}, state, { loading: true })
        }
        case "PREFERENCES_REJECTED": {
            const message = payload?.response?.data?.message || payload?.message || 'Unknown error.'
            return Object.assign({}, state, { error_message: message, loading: false })
        }
        case "SUBMIT_TEAM_PREFERENCE_REJECTED": {
            const message = payload?.response?.data?.message || payload?.message || 'Unknown error.'
            return Object.assign({}, state, { error_message: message, loading: false })
        }
        case "SUBMIT_TEAM_PREFERENCE_PENDING": {
            return Object.assign({}, state, { loading: true })
        }
        case "SUBMIT_TEAM_PREFERENCE_FULFILLED": {
            return Object.assign({}, state, { loading: false })
        }
        case "CLEAR_ERRORS": {
            return Object.assign({}, state, { error_message: null, loading: false })
        }
        case "POLLING-SERVER-MESSAGE": {
            return Object.assign({}, state, { loading_message: payload })
        }
        case "PREFERENCE-RESULT": {
            return Object.assign({}, state, { loading_message: null })
        }
        case "DELETE-PREFERENCE-AND-RESULT": {
            return Object.assign({}, state, { loading_message: null })
        }
        default: {
            return state
        }
    }
}