export const onError = () => {
    return (dispatch) => {
        dispatch({ type: 'ERROR', payload: "WS Network error" })
    }
}

export const onMessage = ({ data: message }) => {
    return (dispatch) => {
        const { action, data } = JSON.parse(message)
        dispatch({ type: action, payload: data });
        // if (type === 'RAISE-HAND') dispatch({ type, payload });
        // if (type === 'USER-STATUS') dispatch({ type, payload });
        // if (type === 'NEW-MESSAGE') dispatch({ type, payload });
        // if (type === 'UNRAISE-HAND') dispatch({ type, payload });
        // if (type === 'CURRENT-MOVE') dispatch({ type, payload });
        // if (type === 'PREFERENCE-SET') dispatch({ type, payload });
        // if (type === 'DOMINANT-SPEAKER') dispatch({ type, payload });
        // if (type === 'PREFERENCE-RESULT') dispatch({ type, payload });
        // if (type === 'UPDATE-SELF-MEDIA') dispatch({ type, payload });
        // if (type === 'UPDATE-PARTICIPANT-MEDIA') dispatch({ type, payload });
        // if (type === 'UPDATE-PARTICIPANTS-MEDIA') dispatch({ type, payload });
        // if (type === 'TOGGLE-PARTICIPANTS-MEDIA') dispatch({ type, payload });
        // if (type === 'DELETE-PREFERENCE-AND-RESULT') dispatch({ type, payload });

    }
}