import axios from '../../axios'

const user = { authenticated: true, logged_in: false, loading: true, fetching_event: true, online: navigator.onLine }

export default (state = user, { type, payload }) => {
    switch (type) {
        case "UPDATE-NETWORK-STATUS": {
            return Object.assign({}, state, { online: navigator.onLine })
        }
        case "SET-EVENT-ID": {
            return Object.assign({}, state, { event_id: payload })
        }
        case "UPDATE-EVENT-STATUS": {
            return Object.assign({}, state, payload)
        }
        case "FETCH-EVENT_FULFILLED": {
            return Object.assign({}, state, payload.data, { fetching_event: false })
        }
        case "FETCH-EVENT_REJECTED": {
            return Object.assign({}, state, { fetching_event: false, event_error_message: "Invalid event please check url." })
        }
        case "AUTHENTICATED": {
            axios.defaults.headers['authorization'] = payload.accessToken
            return Object.assign({}, state, { loading: false, access_token: payload.accessToken, refresh_token: payload.refreshToken })
        }
        case "UNAUTHORIZED": {
            localStorage.removeItem(`${state.event_id}_access_token`);
            localStorage.removeItem(`${state.event_id}_refresh_token`);
            return { loading: false, logged_in: false, authenticated: false, fetching_event: true }
        }
        case "FETCH-PROFILE_FULFILLED": {
            console.log(payload.data)
            return Object.assign({}, state, payload.data, { authenticated: true, logged_in: true })
        }
        case "FETCH-PROFILE_REJECTED": {
            console.log(payload?.response?.data?.message);
            localStorage.removeItem(`${state.event_id}_access_token`);
            localStorage.removeItem(`${state.event_id}_refresh_token`);
            return Object.assign({}, { authenticated: false, logged_in: false, loading: false, event_id: null })
        }
        case "LOGIN_PENDING": {
            return Object.assign({}, state, { login_error_message: null, logging_in: true })
        }
        case "LOGIN_FULFILLED": {
            console.log(payload.data)
            localStorage.setItem(`${state.event_id}_access_token`, payload.data.access_token)
            localStorage.setItem(`${state.event_id}_refresh_token`, payload.data.refresh_token)
            axios.defaults.headers['authorization'] = payload.data.access_token
            return Object.assign({}, state, { authenticated: true, logged_in: false, logging_in: false })
        }
        case "LOGIN_REJECTED": {
            localStorage.removeItem(`${state.event_id}_access_token`);
            localStorage.removeItem(`${state.event_id}_refresh_token`);
            return Object.assign({}, state, { authenticated: false, logged_in: false, login_error_message: payload?.response?.data?.message, logging_in: false })
        }
        case "LOGGED-OUT": {
            localStorage.removeItem(`${state.event_id}_access_token`);
            localStorage.removeItem(`${state.event_id}_refresh_token`);
            const { protocol, hostname, port } = window.location;
            window.location.replace(`${protocol}//${hostname}:${port ? port : ''}/${state.event_id}`);
            return Object.assign({}, { authenticated: false, logged_in: false, loading: false })
        }
        case "TOKEN-REFRESHED-SUCCESSFULLY": {
            localStorage.setItem(`${state.event_id}_access_token`, payload.data.access_token)
            localStorage.setItem(`${state.event_id}_refresh_token`, payload.data.refresh_token)
            axios.defaults.headers['authorization'] = payload.data.access_token
            return Object.assign({}, state, payload.data)
        }
        case "TOKEN-REFRESHED-UNSUCCESSFULLY": {
            localStorage.removeItem(`${state.event_id}_access_token`);
            localStorage.removeItem(`${state.event_id}_refresh_token`);
            return Object.assign({}, { authenticated: false, logged_in: false, loading: false, event_id: null })
        }
        case "UPDATE-SELF-MEDIA": {
            return Object.assign({}, state, payload)
        }
        case "RAISE-HAND_PENDING": {
            return Object.assign({}, state, { raise_hand_loading: true })
        }
        case "RAISE-HAND_FULFILLED": {
            return Object.assign({}, state, { raise_hand: payload.data.raise_hand, raise_hand_loading: false })
        }
        case "RAISE-HAND_REJECTED": {
            console.log("RAISE-HAND_REJECTED:", payload.response)
            return Object.assign({}, state, { raise_hand_loading: false })
        }
        case "LOWERED-HAND": {
            return Object.assign({}, state, { raise_hand: false })
        }
        case "SUBMIT_TEAM_PREFERENCE_FULFILLED": {
            const { data } = payload
            return Object.assign({}, state, { preference: Object.assign({}, state.preference, data) })
        }
        case "PREFERENCE-SET": {
            return Object.assign({}, state, { preference: Object.assign({}, state.preference, payload) })
        }
        case "PREFERENCE-RESULT": {
            return Object.assign({}, state, { preference_result: Object.assign({}, state.preference_result, payload) })
        }
        case "DELETE-PREFERENCE-AND-RESULT": {
            const preference = state.preference;
            delete preference[`move_${payload}`];
            const preferenceResult = state.preference_result;
            delete preferenceResult[`move_${payload}`];
            return Object.assign({}, state, { preference_result: preferenceResult, preference });
        }
        default: {
            return state
        }
    }
}