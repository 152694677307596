import { useState, useEffect } from 'react';

export default (callback) => {
    const [values, setValues] = useState({ email: "", password: "" })
    const [errors, setErrors] = useState({})
    const [isSubmitting, setSubmitting] = useState(false)

    const handleChange = ({ target: { name, value } }) => {
        setValues((prevState) => ({ ...prevState, [name]: value }))
        if (errors[name]) {
            setErrors((prevState) => {
                prevState[name] = null
                return prevState
            })
        }
    }

    const validate = () => {
        const errors = {}
        // const emailRegex = /^([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,5})$/
        const emailRegex = /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/
        if (!values.email) {
            errors.email = "Email is required."
        }
        else if (!emailRegex.test(values.email)) {
            errors.email = "Invalid Email"
        }
        if (!values.password) {
            errors.password = "Password is required."
        }
        return errors
    }

    const handleSubmit = () => {
        setErrors(validate())
        setSubmitting(true)
    }

    useEffect(() => {
        if (errors && Object.values(errors).length === 0 && isSubmitting) {
            callback()
            setSubmitting(false)
        } else {
            setSubmitting(false)
        }
    }, [callback, errors, isSubmitting])

    return { errors, values, handleChange, handleSubmit, isSubmitting }
}