const initialState = {
    participants: {},
    loading: false,
    roomEmpty: true,
    totalVisible: 0,
    totalConnected: 0,
    meeting_type: null,
    allowedToPlay: true,
    screenSharing: false,
    size: { width: "100%", height: "100%" },
}
export default (state = initialState, { type, payload }) => {
    switch (type) {
        case "ALLOWED-TO-PLAY": {
            return Object.assign({}, { participants: {}, allowedToPlay: payload, roomEmpty: true });
        }
        case "FETCH-PARTICIPANTS_PENDING": {
            return Object.assign({}, { participants: {}, loading: true, roomEmpty: true, allowedToPlay: true });
        }
        case "FETCH-PARTICIPANTS_FULFILLED": {
            return Object.assign({}, {
                loading: false, roomEmpty: true, allowedToPlay: true,
                participants: payload.data.reduce((users, user) => {
                    const { first_name, last_name, email: identity, profile_picture: picture, raise_hand, show, audio, video, type, country_abbreviation, team_name } = user
                    const name = first_name.concat(' ', last_name)
                    users[identity] = { show, audio, video, name, country: country_abbreviation, team_name, identity, picture, type, raise_hand, audioTrack: {}, videoTrack: {} }
                    return users;
                }, {})
            })
        }
        case "FETCH-PARTICIPANTS_REJECTED": {
            console.log(payload);
            return state
        }
        case "DISCONNECTED-FROM-ROOM": {
            return Object.assign({}, { participants: {}, roomEmpty: true, allowedToPlay: state.allowedToPlay })
        }
        case "ADD-CURRENT-PARTICIPANTS": {
            const { meeting_type, rps } = payload
            const participants = Object.assign({}, state.participants, rps.reduce((acc, rp) => {
                const { identity, state: status, tracks } = rp
                const participant = state.participants[identity];
                if (participant) {
                    const remoteTracks = getTracks(tracks);
                    acc[identity] = Object.assign({}, participant, remoteTracks, { state: status })
                }
                return acc
            }, {}))
            const screenSharing = isScreenSharing(participants);
            const { totalConnected, totalVisible } = getTotalConnectedVisible(participants);
            const totalItem = meeting_type === 'group' ? totalVisible : totalConnected;
            const size = getGridItemSize(totalItem);
            return Object.assign({}, state, { participants, screenSharing, totalConnected, totalVisible, size, roomEmpty: (totalItem === 0), meeting_type })
        }
        case "UPDATE-PARTICIPANTS-STATUS": {
            const { rp: { identity, state: status }, meeting_type } = payload
            const participant = state.participants[identity]
            if (participant) {
                if (status === 'reconnecting') {
                    return Object.assign({}, state, {
                        participants: Object.assign({}, state.participants, {
                            [identity]: Object.assign({}, participant, { state: status })
                        })
                    })
                } else {
                    const participants = Object.assign({}, state.participants, {
                        [identity]: Object.assign({}, participant, { state: status })
                    })
                    const screenSharing = isScreenSharing(participants);
                    const { totalConnected, totalVisible } = getTotalConnectedVisible(participants);
                    const totalItem = meeting_type === 'group' ? totalVisible : totalConnected;
                    const size = getGridItemSize(totalItem);
                    return Object.assign({}, state, { participants, screenSharing, totalConnected, totalVisible, size, roomEmpty: (totalItem === 0), meeting_type })
                }
            } else {
                return state
            }
        }
        case "UPDATE-PARTICIPANTS-TRACK": {
            const { rp: { identity, tracks }, kind } = payload
            const participant = state.participants[identity]
            if (participant) {
                const remoteTrack = getTrack(tracks, kind);
                const participants = Object.assign({}, state.participants, {
                    [identity]: Object.assign({}, participant, remoteTrack)
                })
                const screenSharing = isScreenSharing(participants);
                return Object.assign({}, state, { participants, screenSharing })
            } else {
                return state
            }
        }
        case "UPDATE-PARTICIPANTS-MEDIA": {
            const { expert, moderator, participant, show, audio, video } = payload
            let participants = Object.values(state.participants).filter(({ type }) => {
                return (expert && type === "expert") ||
                    (moderator && type === "moderator") ||
                    (participant && type === "participant")
            });

            participants = Object.assign({}, state.participants, participants.reduce((acc, currentItem) => {
                const email = currentItem.identity;
                const participant = state.participants[email];
                acc[email] = Object.assign({}, participant, { show, audio, video });
                return acc;
            }, {}));

            const { totalConnected, totalVisible } = getTotalConnectedVisible(participants);
            const totalItem = state.meeting_type === 'group' ? totalVisible : totalConnected;
            const size = getGridItemSize(totalItem);
            return Object.assign({}, state, { participants, totalConnected, totalVisible, size, roomEmpty: (totalItem === 0) })
        }
        case "UPDATE-PARTICIPANT-MEDIA": {
            if (state.participants[payload.email]) {
                const id = payload.email
                const participant = state.participants[id]
                const participants = Object.assign({}, state.participants, {
                    [id]: Object.assign({}, participant, payload.body)
                })

                const { totalConnected, totalVisible } = getTotalConnectedVisible(participants);
                const totalItem = state.meeting_type === 'group' ? totalVisible : totalConnected;
                const size = getGridItemSize(totalItem);
                return Object.assign({}, state, { participants, totalConnected, totalVisible, size, roomEmpty: (totalItem === 0) })
            } else {
                return state
            }
        }
        case "TOGGLE-PARTICIPANTS-MEDIA": {
            const participants = Object.assign({}, state.participants, payload.reduce((acc, currentItem) => {
                const { email, body } = currentItem
                const participant = state.participants[email]
                acc[email] = Object.assign({}, participant, body)
                return acc
            }, {}));

            const { totalConnected, totalVisible } = getTotalConnectedVisible(participants);
            const totalItem = state.meeting_type === 'group' ? totalVisible : totalConnected;
            const size = getGridItemSize(totalItem);
            return Object.assign({}, state, { participants, totalConnected, totalVisible, size, roomEmpty: (totalItem === 0) })
        }
        case "MANAGE-PARTICIPANTS_PENDING": {
            return Object.assign({}, state, { manage_participants_loading: true });
        }
        case "MANAGE-PARTICIPANTS_REJECTED": {
            console.log(payload.response.data);
            return Object.assign({}, state, { manage_participants_loading: false });
        }
        case "MANAGE-PARTICIPANTS_FULFILLED": {
            return Object.assign({}, state, { manage_participants_loading: false });
        }
        case "MANAGE-PARTICIPANT_PENDING": {
            return Object.assign({}, state, { manage_participant_loading: true });
        }
        case "MANAGE-PARTICIPANT_REJECTED": {
            console.log(payload.response.data);
            return Object.assign({}, state, { manage_participant_loading: false });
        }
        case "MANAGE-PARTICIPANT_FULFILLED": {
            return Object.assign({}, state, { manage_participant_loading: false });
        }
        case "TOGGLE-PARTICIPANT_PENDING": {
            return Object.assign({}, state, { manage_participant_loading: true });
        }
        case "TOGGLE-PARTICIPANT_REJECTED": {
            console.log(payload.response.data);
            return Object.assign({}, state, { manage_participant_loading: false });
        }
        case "TOGGLE-PARTICIPANT_FULFILLED": {
            return Object.assign({}, state, { manage_participant_loading: false });
        }
        case "LOWER-HAND_PENDING": {
            return Object.assign({}, state, { manage_participant_loading: true });
        }
        case "LOWER-HAND_REJECTED": {
            return Object.assign({}, state, { manage_participant_loading: false });
        }
        case "LOWER-HAND_FULFILLED": {
            const email = payload.data.email
            const participant = state.participants[email]
            const participants = Object.assign({}, state.participants, { [email]: { ...participant, raise_hand: false } });
            return Object.assign({}, state, { participants, manage_participant_loading: false });
        }
        case "RAISE-HAND": {
            const email = payload.email
            const participant = state.participants[email]
            const participants = Object.assign({}, state.participants, { [email]: { ...participant, raise_hand: true } });
            return Object.assign({}, state, { participants });
        }
        case "LOWER-HAND": {
            const email = payload.email
            const participant = state.participants[email]
            const participants = Object.assign({}, state.participants, { [email]: { ...participant, raise_hand: false } });
            return Object.assign({}, state, { participants });
        }
        default: {
            return state
        }
    }
}

const getTrack = (remoteTracks, kind) => {
    remoteTracks = Array.from(remoteTracks.values());
    const publication = remoteTracks.find((p) => p.kind === kind);
    const isSubscribed = publication.isSubscribed;
    if (isSubscribed) {
        const remoteTrack = publication.track;
        return {
            [`${kind}Track`]: {
                isSubscribed,
                track: isSubscribed ? remoteTrack : null,
                isEnabled: isSubscribed ? remoteTrack.isEnabled : false,
                isStarted: isSubscribed ? remoteTrack.isStarted : false,
                isSwitchedOff: isSubscribed ? remoteTrack.isSwitchedOff : true,
                isMuted: isSubscribed ? remoteTrack.mediaStreamTrack.muted : true,
                readyState: isSubscribed ? remoteTrack.mediaStreamTrack.readyState : 'ended'
            }
        }
    }
    return { [`${kind}Track`]: {} }
}


const getTracks = (remoteTracks) => {
    remoteTracks = Array.from(remoteTracks.values());
    return remoteTracks.reduce((tracks, publication) => {
        const kind = [`${publication.kind}Track`];
        const isSubscribed = publication.isSubscribed;
        if (isSubscribed) {
            const remoteTrack = publication.track;
            return Object.assign(tracks, {
                [kind]: {
                    isSubscribed,
                    track: isSubscribed ? remoteTrack : null,
                    isEnabled: isSubscribed ? remoteTrack.isEnabled : false,
                    isStarted: isSubscribed ? remoteTrack.isStarted : false,
                    isSwitchedOff: isSubscribed ? remoteTrack.isSwitchedOff : true,
                    isMuted: isSubscribed ? remoteTrack.mediaStreamTrack.muted : true,
                    readyState: isSubscribed ? remoteTrack.mediaStreamTrack.readyState : 'ended'
                }
            })
        }
        return Object.assign(tracks, { [kind]: {} });
    }, {});
}

const getGridItemSize = (totalItem) => {
    let row = 1;
    let column = 1;
    while (totalItem > (column * row)) {
        if (column === row) column++
        else row++
    }
    return { width: `${100 / column}%`, height: `${100 / row}%` }
}

const getTotalConnectedVisible = (participants) => {
    let totalVisible = 0;
    let totalConnected = 0;
    Object.values(participants).forEach(({ state, show, type }) => {
        if (state && state !== 'disconnected') totalConnected++;
        if (show && state && state !== 'disconnected' && type !== "producer" && type !== "streamer") totalVisible++;
    })
    return { totalConnected, totalVisible }
}

const isScreenSharing = (participants) => {
    const p = Object.values(participants).find(({ type }) => type === "producer")
    if (p && p?.state !== 'disconnected') {
        if (p?.videoTrack?.track) {
            return Object.assign({}, p, { name: "System", country: null });
        } else {
            return false
        }
    } else {
        return false
    }
}