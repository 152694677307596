import React, { useEffect, useRef } from 'react';
import styles from './styles.module.css';


const VideoTransition = ({ src }) => {
    const elem = useRef()

    useEffect(() => {
        if (elem.current) {
            const video = elem.current
            const onCanPlay = () => video.play();
            video.addEventListener('canplay', onCanPlay)
            return () => {
                video.removeEventListener('canplay', onCanPlay)
            }
        }
    }, [elem]);

    return (
        <video ref={elem} className={styles.video_transition} autoPlay>
            <source src={src} type="video/mp4" />
        </video>
    )
}

export default React.memo(VideoTransition);