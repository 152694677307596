import React from 'react';
import styles from './styles.module.css';
import Teams from './teams';
import Agenda from './agenda';
import Resources from './resources';
import SelfieViewHome from './selfie-view-home';
import PeaceGameTitle from './peace-game-title';
import { shallowEqual, useSelector } from 'react-redux';

const Sidebar = () => {
    const roomEmpty = useSelector(({ meeting: { roomEmpty, screenSharing } }) => {
        return roomEmpty && !screenSharing;
    }, shallowEqual);

    console.log("sidebar");
    return (
        <div className={styles.sidebar}>
            {!roomEmpty ? <SelfieViewHome /> : <span />}
            <PeaceGameTitle roomEmpty={roomEmpty} />
            <div className={styles.scrollable}>
                <Agenda />
                <Teams />
                <Resources />
            </div>
        </div>
    )
}

export default React.memo(Sidebar);