import React, { useState } from 'react';
import styles from './styles.module.css';
import Avatar from '../avatar';
import Icon from '../../../icon';

const ParticipantType = ({ honorary_title: title, first_name, last_name, team_name, job_title: job, organization_name: org, type, status, total_connected, profile_picture }) => {
    const [show, toggle] = useState(false);
    const name = first_name.concat(" ", last_name);

    return (
        <div className={`${styles.participant} ${styles.expendable}`} onClick={() => toggle(!show)}>
            <Avatar picture={profile_picture} />
            <p className={styles.name}>
                <span>{title} {name}</span>
                <span className={`${styles.tag} ${styles[status || "type"]}`}>
                    {total_connected || status || type}
                </span>
            </p>
            <Icon className={`${styles.arrow_icon} ${styles[show ? "expand" : "collapse"]}`} icon="arrow" />
            {show && <div className={styles.details}>
                {type && <p><b>User Type:</b> {type}</p>}
                {team_name && <p><b>Team Name:</b> {team_name}</p>}
                {job && <p><b>Job Title:</b> {job}</p>}
                {org && <p><b>Organization:</b> {org}</p>}
            </div>}
        </div>
    )
}


export default React.memo(ParticipantType);