import React from 'react';
import styles from './styles.module.css';
import Icon from '../../../../../icon';
import ShareScreen from './share-screen';
import RaisedHandAdmin from './raise-hand-admin';
import SelfieView from '../selfie-view/participant';
import ProducerSelfieView from '../selfie-view/producer';
import RaiseHandNotification from './raise-hand-notification';
import RemoteParticipant from '../remote-participant';
import useMeetingRoom from '../../../../../../hooks/use-meeting-room';

const GroupLayout = ({ room, raise_hand }) => {
    const { roomEmpty, remoteParticipants, size, screenSharing, totalParticipants, type } = useMeetingRoom(room, "group", ({ user, meeting }) => {
        const { participants: ps, size, totalConnected, roomEmpty, screenSharing } = meeting
        return {
            size,
            screenSharing,
            type: user.type,
            totalParticipants: totalConnected,
            roomEmpty: screenSharing ? false : roomEmpty,
            remoteParticipants: Object.values(ps).filter(({ show, state, type }) => {
                return show && state && state !== 'disconnected' && type !== "producer" && type !== "streamer"
            })
        }
    });

    console.log('GroupLayout | render');
    return (
        <div className={styles.group_layout}>
            {type === "producer" && <ProducerSelfieView {...{ room, roomEmpty, totalParticipants, screenSharing }} />}
            {type !== "producer" && <SelfieView {...{ room, roomEmpty, raise_hand, meeting_type: "group", totalParticipants }} />}
            {!roomEmpty && <div className={styles.viewers}><Icon icon="viewers" />{totalParticipants}</div>}
            {screenSharing && <ShareScreen remoteParticipants={remoteParticipants} admin={screenSharing} />}
            {!screenSharing && remoteParticipants.map((rp) => <RemoteParticipant key={rp.identity}  {...{ ...rp, size }} />)}
            {(raise_hand && (type === "producer" || type === "moderator")) ? <RaisedHandAdmin /> : <RaiseHandNotification />}
        </div>
    )
}

export default React.memo(GroupLayout)