
import React, { useEffect, useRef, useState } from "react";
import styles from "../styles.module.css";
import AudioLevelIndicator from "./audio-level-indicator";

const Audio = ({ audioTrack }) => {
    const audioRef = useRef(false);
    const [isAudioPlaying, setAudioPlaying] = useState(false);

    useEffect(() => {
        if (audioTrack) {
            const audio = audioTrack.attach(audioRef.current);
            const onCanPlay = () => audio.play();
            const onPlay = () => setAudioPlaying(true);
            audio.addEventListener('play', onPlay);
            audio.addEventListener('canplay', onCanPlay)
            return () => {
                audioTrack.stop();
                audioTrack.detach();
                setAudioPlaying(false);
                audio.removeEventListener('play', onPlay);
                audio.removeEventListener('canplay', onCanPlay)
            };
        }
    }, [audioRef, audioTrack]);

    return (
        <div className={styles.name}>
            <p className={styles.name_text}>Test User</p>
            <AudioLevelIndicator className={styles.icon} {...{ audioTrack, isAudioPlaying }} />
            <audio ref={audioRef} className={styles.audio} controls={false} onContextMenu={(e) => e.preventDefault()} />
        </div>
    )
};

export default React.memo(Audio);
