import React from "react";
import Audio from './audio';
import Video from './video';
import Icon from '../../../../../icon';
import styles from "./styles.module.css";
import MeetingControls from "./meeting-controls";
import useDrag from "../../../../../../hooks/use-drag";
import { useSelector, shallowEqual } from "react-redux";
import useGetAudioTrack from "../../../../../../hooks/use-get-audio-track";
import useGetVideoTrack from "../../../../../../hooks/use-get-video-track";
import useGetUserDevices from "../../../../../../hooks/use-get-user-devices";

const SelfieView = ({ room, roomEmpty, meeting_type, raise_hand, totalParticipants }) => {
    const lp = room.localParticipant;
    const { elem, handleDrag } = useDrag(roomEmpty);
    const [devices, selected, handleDeviceChange] = useGetUserDevices();
    const [audioTrack, isAudioPublished, handleAudio] = useGetAudioTrack(lp, selected.audio);
    const [videoTrack, isVideoPublished, handleVideo] = useGetVideoTrack(lp, selected.video);
    const { name, picture, isLive, user_raise_hand, type } = useSelector(({ user }) => {
        const { type, profile_picture, raise_hand, first_name, last_name, show } = user
        return {
            type,
            picture: profile_picture,
            user_raise_hand: raise_hand,
            name: first_name.concat(" ", last_name),
            isLive: meeting_type === "group" ? show : true,
        };
    }, shallowEqual);

    const _isLive = (isAudioPublished || isVideoPublished || isLive);

    console.log(`LocalParticipant | render [${name}]:`, { videoTrack, audioTrack });
    return (
        <div id="local-video" ref={elem} className={`${styles.local_video} ${roomEmpty ? styles.full : styles.normal}`} onMouseDown={handleDrag} onContextMenu={(e) => e.preventDefault()}>
            <Video {...{ videoTrack, name, picture }} />
            <Audio {...{ audioTrack, name, user_raise_hand, raise_hand }} />
            <div className={styles.indicator_viewers} >
                <p className={`${styles.indicator} ${_isLive ? styles.live : styles.offline}`}>{_isLive ? "LIVE" : "OFFLINE"}</p>
                {roomEmpty && <div className={styles.viewers}><Icon icon="viewers" />{totalParticipants}</div>}
            </div>
            <MeetingControls {...{ roomEmpty, raise_hand, type, audioTrack, handleAudio, videoTrack, handleVideo, devices, selected, handleDeviceChange }} />
        </div>
    );
};

export default React.memo(SelfieView);
