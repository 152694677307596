import { useState, useEffect } from 'react'

const useFullScreen = () => {
    const [isFullScreen, setFullScreen] = useState(false)

    function openFullscreen() {
        const el = document.getElementById("idOfMAP");
        const requestFullscreen = el.requestFullscreen
            || el.webkitRequestFullScreen
            || el.mozRequestFullScreen
            || el.msRequestFullscreen
        requestFullscreen.call(el);
    }

    function closeFullscreen() {
        const exitFullscreen = document.exitFullscreen
            || document.mozCancelFullScreen
            || document.webkitExitFullscreen
            || document.msExitFullscreen
        exitFullscreen.call(document);
    }


    const handleFullScreen = () => {
        if (!document.fullscreenElement) {
            openFullscreen()
        } else {
            closeFullscreen()
        }
    }

    useEffect(() => {
        const onFullscreenchange = () => {
            setFullScreen(document.fullscreenElement ? true : false);
        }
        document.addEventListener('fullscreenchange', onFullscreenchange);
        return () => {
            document.removeEventListener('fullscreenchange', onFullscreenchange);
        }
    }, [])

    return [isFullScreen, handleFullScreen]
}

export default useFullScreen