import moment from 'moment';
import minLeft from './min-left.mp3';
import singleBeep from './single-beep.mp3';
import { useState, useEffect } from 'react';
import minLeftPolling from './min-left-polling.mp3';
import momentDurationFormatSetup from 'moment-duration-format';

momentDurationFormatSetup(moment);

const useCountTo = (event_time, countdown_time, polling) => {
    const [timeLeft, setTimeLeft] = useState(null);
    const [timeStatus, setTimerStatus] = useState(null);
    const [colorStatus, setColorStatus] = useState(null);
    const countTo = moment(event_time).add(countdown_time, 'minute').add(3, 'second');

    useEffect(() => {
        setTimeLeft(null);
        setTimerStatus(null);
        setColorStatus(null);
    }, [event_time, countdown_time])

    useEffect(() => {
        if (countdown_time) {
            const stopInterval = setInterval(() => {
                const difference = countTo.diff(moment(), 'seconds');
                const timeLeft = moment.duration(difference, 'seconds');
                const seconds = timeLeft.asSeconds()

                console.log('seconds:', seconds)
                console.log('colorStatus:', colorStatus)
                console.log('timeStatus:', timeStatus)
                if (timeLeft.subtract(1, 'seconds').asSeconds() < 0) {
                    setTimerStatus(null);
                    setTimeLeft(null);
                    return clearInterval(stopInterval);
                }
                if (countdown_time > 1 && seconds <= 60 && seconds > 0 && colorStatus !== 'end') {
                    console.log('countdown_time[seconds <= 60]')
                    setColorStatus('end');
                    setTimerStatus(null);
                }
                if (seconds <= 11 && seconds > 0 && timeStatus !== 'flash') {
                    console.log('countdown_time[seconds <= 3]')
                    setTimerStatus('flash');
                }
                setTimeLeft(timeLeft);
            }, 1000)
            return () => clearInterval(stopInterval);
        }
    }, [countTo, countdown_time, colorStatus, timeStatus, polling]);

    useEffect(() => {
        if (timeLeft && timeLeft.asSeconds() <= 60) {
            const seconds = timeLeft.asSeconds()
            if (seconds === 0) {
                // const audio = new Audio(singleBeep);
                // audio.play();
            } else if (countdown_time > 1 && seconds === 60 && seconds > 0) {
                if (polling) {
                    const audio = new Audio(minLeftPolling);
                    audio.play();
                } else {
                    const audio = new Audio(minLeft);
                    audio.play();
                }
            } else if (seconds <= 3 && seconds > 0) {
                const audio = new Audio(singleBeep);
                audio.play();
            }
        }
    }, [countdown_time, timeLeft, polling])

    return { timeLeft, timeStatus, colorStatus }
}

export default useCountTo