import React from 'react'
export default ({ className }) => {
    return (
        <svg className={className} viewBox="0 0 45 45">
            <circle cx="22.5" cy="22.5" r="21.5" fill="white" stroke="#0C6599" strokeWidth="2" />
            <path d="M10.6899 22.5C12.5473 17.788 17.1318 14.45 22.5 14.45C27.8681 14.45 32.4526 17.788 34.3099 22.5C32.4526 27.2118 27.8681 30.55 22.5 30.55C17.1318 30.55 12.5473 27.2118 10.6899 22.5ZM31.9695 22.5C30.198 18.8828 26.569 16.5966 22.5 16.5966C18.4309 16.5966 14.802 18.8828 13.0305 22.5C14.802 26.1171 18.4201 28.4032 22.5 28.4032C26.5797 28.4032 30.198 26.1171 31.9695 22.5ZM22.5 19.8166C23.9816 19.8166 25.1841 21.0188 25.1841 22.5C25.1841 23.9812 23.9816 25.1833 22.5 25.1833C21.0184 25.1833 19.8159 23.9812 19.8159 22.5C19.8159 21.0188 21.0184 19.8166 22.5 19.8166ZM17.6686 22.5C17.6686 19.8381 19.8373 17.67 22.5 17.67C25.1625 17.67 27.3314 19.8381 27.3314 22.5C27.3314 25.1618 25.1625 27.3299 22.5 27.3299C19.8373 27.3299 17.6686 25.1618 17.6686 22.5Z" fill="#0C6599" />
            <line x1="38.7071" y1="7.70711" x2="8.70711" y2="37.7071" stroke="#0C6599" strokeWidth="2" />
        </svg>
    )
}
