import { useState, useEffect } from 'react';
import { useSelector, shallowEqual } from "react-redux";

const usePublishTrack = (lp, track, mediaType) => {
    const [isPublished, setPublished] = useState(false);
    const { publish, isAllowed } = useSelector(({ user, meeting }) => {
        const { publish } = user
        const { meeting_type } = meeting
        return {
            publish: meeting_type === "group" ? publish : true,
            isAllowed: meeting_type === "group" ? user[mediaType] : true,
        };
    }, shallowEqual);

    useEffect(() => {
        if (track && isAllowed && publish) {
            lp.publishTrack(track, { priority: 'high' })
                .then((publication) => {
                    setPublished(true);
                    console.log(`usePublishTrack | Successfully Published Local${mediaType}Track:`, publication);
                }).catch((error) => {
                    console.error(`usePublishTrack | Failed to publish Local${mediaType}Track:`, error);
                    alert(`Sorry could not publish the ${mediaType} to the room: ${error.message}`)
                })
            return () => {
                lp.tracks.forEach((p) => {
                    if (p.track.kind === mediaType) {
                        p.unpublish();
                        setPublished(false);
                        console.log(`usePublishTrack | Unpublish Local${mediaType}Track:`, track);
                    }
                });
            }
        }
    }, [lp, isAllowed, publish, track, mediaType]);

    return [isPublished]
}

export default usePublishTrack;