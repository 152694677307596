import React from 'react'
export default ({ className }) => {
    return (
      <svg className={className} viewBox="0 0 7 10" fill="none">
      <g clipPath="url(#clip0)">
      <path d="M0.920363 0.666656C1.43292 0.666656 1.84447 1.0202 1.84447 1.46132C1.84447 1.90243 1.43666 2.25922 0.924105 2.26246C0.411545 2.26571 0 1.90892 0 1.4678C0 1.02344 0.407803 0.666656 0.920363 0.666656Z" fill="#0C6599" fillOpacity="0.38"/>
      <path d="M4.92036 2.26159C4.4078 2.26159 4 1.9048 4 1.46044C4 1.01608 4.41154 0.662537 4.92411 0.662537C5.43667 0.662537 5.84447 1.01932 5.84447 1.46368C5.84447 1.90805 5.43292 2.26483 4.92036 2.26159Z" fill="#0C6599" fillOpacity="0.38"/>
      <path d="M0.916647 5.79789C0.404087 5.79789 -0.00371577 5.44111 2.55378e-05 4.99675C2.55378e-05 4.55563 0.41157 4.20209 0.92413 4.20209C1.43669 4.20209 1.84449 4.56212 1.84075 5.00323C1.84075 5.4476 1.42921 5.80114 0.916647 5.79789Z" fill="#0C6599" fillOpacity="0.38"/>
      <path d="M4.91665 5.79789C4.40409 5.79789 3.99628 5.43786 4.00003 4.99675C4.00377 4.55239 4.41157 4.20209 4.92787 4.20209C5.44043 4.20209 5.84824 4.55887 5.8445 5.00323C5.84076 5.4476 5.42921 5.80114 4.91665 5.79789Z" fill="#0C6599" fillOpacity="0.38"/>
      <path d="M0.924105 7.73752C1.43292 7.73752 1.84447 8.09106 1.84447 8.53542C1.84447 8.97654 1.43666 9.33333 0.924105 9.33333C0.411545 9.33657 0 8.97978 0 8.53218C0 8.09106 0.411545 7.73752 0.924105 7.73752Z" fill="#0C6599" fillOpacity="0.38"/>
      <path d="M4.92413 9.33332C4.41531 9.33332 4.00377 8.97978 4.00003 8.53866C3.99628 8.09754 4.40783 7.74075 4.91665 7.73751C5.43295 7.73427 5.84449 8.09105 5.84449 8.53541C5.84449 8.97978 5.43295 9.33332 4.92413 9.33332Z" fill="#0C6599" fillOpacity="0.38"/>
      </g>
      <defs>
      <clipPath id="clip0">
      <rect width="7" height="8.66667" fill="white" transform="translate(0 0.666656)"/>
      </clipPath>
      </defs>
      </svg>
      
    )
}