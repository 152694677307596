import React from 'react';
import Icon from '../../../../icon';
import styles from './styles.module.css';

const ErrorMessage = ({ message, type, retry, ok }) => {
    return (
        <div className={styles.error_message} >
            <div className={styles[(type === "error") ? "error" : "modal"]}>
                {type === "error" && <Icon className={styles.icon} icon="error-2" />}
                {message}
                {ok && <button className={styles.retry_button} onClick={ok}>Ok</button>}
                {retry && <button className={styles.retry_button} onClick={retry}>Retry</button>}
            </div>
        </div>
    )
}

export default React.memo(ErrorMessage);