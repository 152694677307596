import React, { useState } from 'react';
import styles from './styles.module.css';
import Icon from "../../../../../../icon";
import TrackStatus from './track-status';
import { useDispatch } from 'react-redux';
import { manageParticipant, toggleParticipant, LowerHand } from '../../../../../../actions/meeting';

const User = ({ name, team_name, identity, raise_hand, audio, video, show, state, videoTrack, audioTrack }) => {
    const dispatch = useDispatch();
    const [expend, setExpend] = useState(false);

    const handleParticipant = (type) => {
        if (type === 'audio') {
            dispatch(manageParticipant(identity, { audio: !audio }));
        } else if (type === 'video') {
            dispatch(manageParticipant(identity, { video: !video }));
        } else {
            const data = {
                video: true,
                audio: true,
                show: !show,
                publish: !show,
            }
            dispatch(manageParticipant(identity, data, raise_hand));
        }
    }

    const handelToggle = () => {
        dispatch(toggleParticipant(identity, raise_hand));
    }

    const handelLowerHand = () => {
        dispatch(LowerHand(identity));
    }

    return (
        <div className={styles.user}>
            <div className={`${styles.status} ${styles[state ? state : "disconnected"]}`} />
            <p className={styles.name}>{name} {team_name && `(${team_name})`}</p>
            <button className={styles.buttons} onClick={handelLowerHand} disabled={!raise_hand}>
                <Icon className={styles.icon} icon={raise_hand ? "raise-hand" : "raise-hand-off"} />
            </button>
            <button className={styles.buttons} onClick={handelToggle}>
                <Icon className={styles.icon} icon="switch" />
            </button>
            <button className={styles.buttons} onClick={() => handleParticipant('show')}>
                <Icon className={styles.icon} icon={show ? "eye" : "eye-off"} />
            </button>
            <button className={styles.buttons} onClick={() => handleParticipant('audio')}>
                <Icon className={styles.icon} icon={audio ? "audio-unmute" : "audio-mute"} />
            </button>
            <button className={styles.buttons} onClick={() => handleParticipant('video')}>
                <Icon className={styles.icon} icon={video ? "video-unmute" : "video-mute"} />
            </button>
            <button className={styles.arrow_buttons} onClick={() => setExpend(!expend)}>
                <Icon className={`${styles.arrow} ${styles[expend && "expend"]}`} icon="arrow" />
            </button>
            {expend && <div className={styles.tracks_status}>
                <TrackStatus track={videoTrack} type="Video" />
                <TrackStatus track={audioTrack} type="Audio" />
            </div>}
        </div>
    )
}

export default React.memo(User)

