import React from 'react';
import styles from './styles.module.css';
import Icon from '../../../../../../../icon';
import CloseControl from '../../../close-control';

const SettingMenu = ({ roomEmpty, devices, selected, handleDeviceChange, toggle }) => {
    return (
        <div className={styles[roomEmpty ? "setting_menu" : "setting_menu_small"]}>
            <CloseControl onClick={() => toggle(false)} />
            <div className={styles.select_input}>
                <label>Camera</label>
                {devices.video.length ? <select value={selected.video} name="videoInput" onChange={handleDeviceChange}>
                    {devices.video.map((device, i) => <option key={i} value={device.deviceId}>{device.label}</option>)}
                </select> : <p>No Camera Device Found.</p>}
                <Icon className={styles.icon} icon="webcam" />
            </div>
            <div className={styles.select_input}>
                <label>Microphone</label>
                {devices.audio.length ? <select value={selected.audio} name="audioInput" onChange={handleDeviceChange}>
                    {devices.audio.map((device, i) => <option key={i} value={device.deviceId}>{device.label}</option>)}
                </select> : <p>No Microphone Device Found.</p>}
                <Icon className={styles.icon} icon="microphone-1" />
            </div>
        </div>
    )
}

export default React.memo(SettingMenu);