import React, { useState } from 'react'
import styles from './styles.module.css'
import Icon from '../icon'

const TawkTo = () => {
    const [show, toggle] = useState(false);
    const [loading, setLoading] = useState(true);
    const src = "https://tawk.to/chat/5f50f5374704467e89ebee36/default";

    return (
        <div className={styles.tawk_to}>
            <button className={styles.button} onClick={() => toggle(!show)}>
                <Icon className={styles.message_icon} icon="message" />
                <p className={styles.button_text}>Need tech support?</p>
                <Icon className={styles[show ? "arrow_icon_open" : "arrow_icon"]} icon="arrow" />
            </button>
            <div className={`${styles.iframe_wrapper} ${styles[show ? "show" : "hide"]}`}>
                <iframe
                    src={src}
                    title="tawk-to"
                    frameBorder="0"
                    className={styles.iframe}
                    onLoad={() => setLoading(false)}
                />
                {loading && <Icon className={styles.loading} icon="loading" />}
            </div>
        </div>
    );
}
export default React.memo(TawkTo);