import React, { useState } from "react";
import Audio from './audio';
import Video from './video';
import styles from "./styles.module.css";
import MeetingControls from "./meeting-controls";
import useRatioHeight from '../../../hooks/use-ratio-height';

const SelfieView = () => {
    const [audioTrack, setAudioTrack] = useState(null);
    const [videoTrack, setVideoTrack] = useState(null);
    const [elem, height] = useRatioHeight(16, 9, false, 0);

    return (
        <div ref={elem} className={styles.local_video} style={{ height }}>
            <Video {...{ videoTrack }} />
            <Audio {...{ audioTrack }} />
            <MeetingControls {...{ audioTrack, videoTrack, setAudioTrack, setVideoTrack }} />
        </div>
    );
};

export default React.memo(SelfieView);
