import React, { useState } from 'react';
import Avatar from '../avatar';
import Icon from '../../../icon';
import styles from './styles.module.css';
import Participant from '../participant';

const ParticipantType = ({ team_name, honorary_title, first_name, last_name, job_title, organization_name, type, profile_picture, image_url, participants, tag }) => {
    const [show, toggle] = useState(false)
    const picture = image_url || profile_picture;
    const expendable = (participants?.length > 0);
    const name = type ? first_name.concat(' ', last_name) : team_name;

    const handelToggle = () => {
        if (expendable) toggle(!show)
    }

    return (
        <div className={`${styles.participant} ${expendable && styles.expendable}`} onClick={handelToggle}>
            <Avatar picture={picture} />
            <p className={styles.name}>
                <span>{honorary_title} {name}</span>
                {(tag || type) && <span className={`${styles.tag} ${styles.type}`}>{tag || type}</span>}
            </p>
            {expendable && <Icon className={`${styles.arrow_icon} ${styles[show ? "expand" : "collapse"]}`} icon="arrow" />}
            <div className={styles.participants}>
                {(!show) && <p>
                    {/* {type ? <span>{[job_title, organization_name].filter((item) => item).join(", ")}</span> : */}
                    {type ? <span>{job_title !== "-" ? job_title : null}{job_title !== "-" && <br></br>}{organization_name}</span> :
                        <span>{participants?.length} {participants?.length === 1 ? "team member" : "team members"}</span>}
                </p>}
                {(show && expendable) && participants?.map((p, i) => <Participant key={i} {...p} />)}
            </div>
        </div>
    )
}


export default React.memo(ParticipantType);