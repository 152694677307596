import React from 'react'
import styles from './styles.module.css'
import Icon from "../icon";
import moment from 'moment';
import ErrorMessage from './error-message';

const EventTitle = ({ fetching_event, event_title, event_date, event_error: error_message }) => {
    if (fetching_event && !error_message) {
        return (
            <div className={styles.event_title}>
                <Icon className={styles.loading} icon="loading" />
            </div>
        )
    } else if (!fetching_event && error_message) {
        return <ErrorMessage {...{ error_message }} />
    } else {
        return (
            <div className={styles.event_title}>
                <p>{event_title}</p>
                {event_date && <p>{moment(event_date).format('MMMM Do YYYY')}</p>}
            </div>
        )
    }
}

export default React.memo(EventTitle);