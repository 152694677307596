import React from 'react';
import styles from "./styles.module.css"
import Icon from '../../../../../icon'

const Preferences = ({ swapPreferredTeam, isAvailable, index, blank, name, id, disable }) => {
    return (
        <div className={`${styles.rank_item} ${styles[!isAvailable && "rank_item_preferred"]}`}
            id={id} draggable={!disable}
            onDragOver={(event) => {
                event.preventDefault();
            }}
            onDragStart={(event) => {
                event.dataTransfer.setData("data", JSON.stringify({ d_index: index, d_isAvailable: isAvailable, d_blank: blank }));

            }}
            onDrop={(event => {
                event.preventDefault()
                const { d_index, d_isAvailable, d_blank } = JSON.parse(event.dataTransfer.getData("data"))
                const dragIDX = d_index;
                const swapIDX = index;
                const isSameColumn = d_isAvailable === isAvailable
                const useBlank = isAvailable ? d_blank : blank
                swapPreferredTeam(dragIDX, swapIDX, isAvailable, isSameColumn, useBlank)
            })}
        >
            <Icon className={styles.rank_item_icon} icon="rank-team" />  <p className={styles.rank_item_team_name}>{name} </p>
        </div>
    )
}


export default React.memo(Preferences)