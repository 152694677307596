import React from 'react';
import styles from './styles.module.css';
import ErrorMessage from './error-message';
import VideoTransition from './video-transition';
import MeetingTransition from './meeting-transition';
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import useRatioHeight from '../../../../hooks/use-ratio-height';
import MeetingControl from './meeting-transition/meeting-controls';

const Meeting = ({ isFullScreen }) => {
    const dispatch = useDispatch();
    const [elem, height] = useRatioHeight(16, 9, isFullScreen, 0);
    const { stage_type, video_src, image_src, transition_type, raise_hand, meeting_type, allowed_to_Play, type } = useSelector(({ moves: list, user, meeting }) => {
        const { moves, current } = list
        const [move, _stage] = current
        const stage = moves[move][_stage]
        return {
            type: user.type,
            stage_type: stage.type,
            image_src: stage.image_src,
            video_src: stage.video_src,
            raise_hand: stage.raise_hand,
            meeting_type: stage.meeting_type,
            transition_type: stage.transition_type,
            allowed_to_Play: meeting.allowedToPlay,
        }
    }, shallowEqual);

    const meetingType = () => {
        if (stage_type === "transition") {
            if (transition_type === "image-transition") {
                return <img className={styles.transition} src={image_src} alt="" />
            } else if (transition_type === "video-transition") {
                return <VideoTransition src={video_src} />
            } else {
                return <ErrorMessage type="error" message={`Invalid transition type ${transition_type}.`} />
            }
        } else if (stage_type === "meeting") {
            console.log("allowed_to_Play:", allowed_to_Play)
            if (!allowed_to_Play) {
                const error = {
                    type: "error",
                    ok: () => dispatch({ type: "ALLOWED-TO-PLAY", payload: true }),
                    message: "Your browser window became inactive. Please click ok to activate the window and join the meeting.",
                }
                return <ErrorMessage {...error} />
            } else {
                return <MeetingTransition {...{ raise_hand, meeting_type }} />
            }
        } else {
            return <ErrorMessage type="error" message={`Invalid stage type ${stage_type}.`} />
        }
    }

    return (
        <div ref={elem} className={`${styles.meeting_container} ${styles[isFullScreen && 'fullScreen']}`} style={{ height }}>
            {meetingType()}
            {type === "producer" && < MeetingControl />}
        </div>
    )
}

export default React.memo(Meeting)