import React, { useState } from 'react';
import styles from './styles.module.css'
import Icon from '../../../../../../icon';
import CloseControl from '../../close-control';
import useDrag from '../../../../../../../hooks/use-drag';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { manageParticipants } from '../../../../../../actions/meeting';

const ParticipantControl = ({ handelClick }) => {
    const dispatch = useDispatch();
    const { elem, handleDrag } = useDrag(false);
    const [values, setValued] = useState({
        participant: false, expert: false, moderator: false,
        show: false, publish: false, audio: false, video: false
    });
    const { loading } = useSelector(({ meeting: m }) => {
        return { loading: m.manage_participants_loading }
    }, shallowEqual);

    const handleChange = ({ target: { name, checked } }) => {
        setValued((prevValues) => ({ ...prevValues, [name]: checked }));
    }

    const handleSubmit = () => {
        dispatch(manageParticipants(values));
    }

    return (
        <div ref={elem} className={styles.participant_control} onMouseDown={handleDrag}>
            <CloseControl onClick={() => handelClick("participant_control")} />
            <div className={styles.audiences}>
                <p className={styles.title}>Audiences:</p>
                <div className={styles.checkboxes}>
                    <div className={styles.checkbox}>
                        <input type="checkbox" name="participant" checked={values.participant} onChange={handleChange} />
                        <p>Participant</p>
                    </div>
                    <div className={styles.checkbox}>
                        <input type="checkbox" name="expert" checked={values.expert} onChange={handleChange} />
                        <p>Expert</p>
                    </div>
                    <div className={styles.checkbox}>
                        <input type="checkbox" name="moderator" checked={values.moderator} onChange={handleChange} />
                        <p>Moderator</p>
                    </div>
                </div>
            </div>
            <div className={styles.controls}>
                <p className={styles.title}>Controls:</p>
                <div className={styles.controlCheckboxes}>
                    <div className={styles.controlCheckbox}>
                        <input type="checkbox" name="show" checked={values.show} onChange={handleChange} />
                        <p>Show</p>
                    </div>
                    <div className={styles.controlCheckbox}>
                        <input type="checkbox" name="publish" checked={values.publish} onChange={handleChange} />
                        <p>Publish</p>
                    </div>
                    <div className={styles.controlCheckbox}>
                        <input type="checkbox" name="video" checked={values.video} onChange={handleChange} />
                        <p>Video</p>
                    </div>
                    <div className={styles.controlCheckbox}>
                        <input type="checkbox" name="audio" checked={values.audio} onChange={handleChange} />
                        <p>Audio</p>
                    </div>
                </div>
            </div>
            <button className={styles.submit_button} onClick={handleSubmit} disabled={loading}>
                <p>SUBMIT</p> {loading && <Icon className={styles.loading} icon="loading" />}
            </button>
        </div>
    )
}

export default React.memo(ParticipantControl);