import React, { useEffect, useState } from 'react';
import styles from './styles.module.css';
import GroupMeeting from './group-meeting';
import ErrorMessage from '../error-message';
import useCreateRoom from '../../../../hooks/use-create-room';

const MeetingLayout = () => {
    const [time, setTime] = useState(0);
    const { room, joining, errorMessage } = useCreateRoom("group", true);

    // wait for 5 second before joining the meeting
    useEffect(() => {
        const interval = setInterval(() => {
            setTime((prevTime) => {
                if (prevTime >= 5) {
                    clearInterval(interval)
                    return prevTime
                }
                return prevTime + 1
            })
        }, 1000)
        return () => clearInterval(interval)
    }, []);

    console.log("Meeting:", { room, time });
    if (!room && joining && errorMessage) {
        return <ErrorMessage type="error" message={errorMessage} />
    } else if (!room && joining) {
        return <ErrorMessage message="Joining now..." />
    } else if (room && !joining && time < 5) {
        return <ErrorMessage message="Joining now..." />
    } else if (room && !joining) {
        return (
            <div className={styles.meeting}>
                <GroupMeeting {...{ room }} />
            </div>
        )
    } else {
        return <ErrorMessage type="error" message="Unknown error." />
    }
}

export default React.memo(MeetingLayout)