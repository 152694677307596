import React from 'react';
import { shallowEqual, useSelector } from "react-redux"
import moment from "moment"
import styles from './styles.module.css'

const PeaceGameTitle = ({ roomEmpty }) => {
    const { event_title, event_date } = useSelector(({ user }) => {
        const { event_title, event_date } = user;
        return { event_title, event_date };
    }, shallowEqual);

    return (
        <div className={`${styles.peace_game_title} ${roomEmpty && styles.margin_top}`}>
            <p>{event_title}</p>
            <p>{moment(event_date).format('MMMM D YYYY')}</p>
        </div>
    );
}

export default React.memo(PeaceGameTitle);