import React from "react";
import styles from "./styles.module.css";
import Avatar from "../../../../avatar";
import usePlayLocalTrack from "../../../../../../hooks/use-play-local-track";

const Video = ({ picture, name, videoTrack }) => {
    const [isVideoPlaying, videoRef] = usePlayLocalTrack(videoTrack);

    return (
        <>
            {!isVideoPlaying && <Avatar className={styles.avatar} picture={picture} name={name} />}
            <video ref={videoRef} className={styles[isVideoPlaying ? 'video' : 'hide_video']} onContextMenu={(e) => e.preventDefault()} />
        </>
    )
};

export default React.memo(Video);
