import React, { useRef } from 'react';
import styles from './styles.module.css';

const SelfieViewHome = () => {
    const elem = useRef();
    const reset = () => {
        const selfieView = document.getElementById("local-video");
        const { top, left } = elem.current.getBoundingClientRect();
        selfieView.style.top = `${top}px`;
        selfieView.style.left = `${left}px`;
    };

    return (
        <div ref={elem} className={styles.selfie_view_home}>
            <button className={styles.reset_button} onClick={reset}>Reset selfie view</button>
        </div>
    )
}

export default React.memo(SelfieViewHome);