import React, { useEffect } from 'react';
import styles from './styles.module.css'
import Icon from "../icon";
import TawkTo from '../tawk-to';
import Loading from '../loading';
import Input from '../text-input';
import EventTitle from './event-title';
import ErrorMessage from './error-message';
import DotBackground from './dot-background';
import useValidation from './use-validation';
// import logo from '../images/AGDA.png';
import logo2 from '../images/FP Peace Game_H_White.png';
import { login, fetchEvent } from '../actions/user';
import { useParams, useLocation } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

const useQuery = () => {
    const query = {}
    const queryStringParams = new URLSearchParams(useLocation().search);
    queryStringParams.forEach((value, key) => query[key] = value);
    return query;
}

const LoginPage = () => {
    const dispatch = useDispatch();
    const { event_id } = useParams();
    const { email, password } = useQuery();
    const { values, handleChange, handleSubmit, errors } = useValidation(submit);
    const { event_error, login_error, logging_in, fetching_event, event_title, event_date } = useSelector(({ user, }) => {
        return {
            error: user.error,
            logging_in: user.logging_in,
            event_date: user.event_date,
            event_title: user.event_title,
            fetching_event: user.fetching_event,
            event_error: user.event_error_message,
            login_error: user.login_error_message
        }
    }, shallowEqual);


    function submit() {
        dispatch(login(values, event_id))
    }

    useEffect(() => {
        if (email && password) {
            dispatch(login({ email, password }, event_id))
        } else {
            dispatch(fetchEvent(event_id));
        }
        return () => dispatch({ type: "REMOVE-ALL-ERRORS" });
    }, [dispatch, event_id, email, password]);

    if (email && password) {
        if (logging_in) {
            return <Loading />
        } else if (login_error) {
            return <Loading message={login_error} />
        } else {
            return <Loading message="Unknown error." />
        }
    } else {
        return (
            <div className={styles.login_form}>
                {/* <img className={styles.logo} src={logo} alt="" /> */}
                <img className={styles.logo_2} src={logo2} alt="" />
                <EventTitle {...{ fetching_event, event_title, event_date, event_error }} />
                {(!fetching_event && !event_error) && <>
                    {login_error && <ErrorMessage {...{ error_message: login_error }} />}
                    <Input
                        className={styles.email}
                        error={errors?.email}
                        placeholder="Enter your email"
                        type="email" name="email" value={values.email} onChange={handleChange}
                    />
                    <Input
                        className={styles.password}
                        error={errors?.password}
                        placeholder="Enter your password"
                        type="password" name="password" value={values.password} onChange={handleChange}
                    />
                    <button className={styles.submit_button} onClick={handleSubmit} disabled={logging_in}>
                        <p>Login</p> {logging_in && <Icon className={styles.submit_button_loading} icon='loading' />}
                    </button>
                    <TawkTo />
                </>}
                <DotBackground />
            </div>
        );
    }
}

export default React.memo(LoginPage);