import React, { useState } from 'react';
import styles from './styles.module.css';
import Icon from '../../../../../../icon';
import { useDispatch } from 'react-redux';
import CloseControl from '../../close-control';
import useDrag from '../../../../../../../hooks/use-drag';
import { sendAnnouncement } from '../../../../../../actions/chat';

const UserSearch = ({ handelClick }) => {
    const dispatch = useDispatch();
    const [text, setText] = useState("");
    const { elem, handleDrag } = useDrag(false);

    const handleClick = () => {
        if (text.trim().length > 0) {
            dispatch(sendAnnouncement({ message: text.trim() }));
            setText("");
        }
    }

    const textSubmit = (e) => {
        if (e.keyCode === 13 && !e.shiftKey) {
            e.preventDefault();
            handleClick();
        }
    }

    return (
        <div ref={elem} className={styles.system_announcement} onMouseDown={handleDrag}>
            <CloseControl onClick={() => handelClick("send_announcement")} />
            <div className={styles.input_wrapper}>
                <span>{text}</span>
                <textarea
                    className={styles.input}
                    value={text}
                    onKeyDown={textSubmit}
                    placeholder="Send a message ..."
                    onChange={(e) => setText(e.target.value)}
                />
            </div>
            <button className={styles.send_button} type="submit" onClick={handleClick}>
                <Icon className={styles.send_icon} icon="chat-input" />
            </button>
        </div>
    )
}

export default React.memo(UserSearch)