import React from 'react';
import styles from './styles.module.css';
import Icon from '../../../../../icon';
import SelfieView from '../selfie-view/participant';
import RemoteParticipant from '../remote-participant';
import useMeetingRoom from '../../../../../../hooks/use-meeting-room';

const TeamLayout = ({ room }) => {
    const { roomEmpty, remoteParticipants, size, totalParticipants } = useMeetingRoom(room, "team", ({ meeting }) => {
        const { participants: ps, size, totalConnected, roomEmpty } = meeting
        return {
            size,
            roomEmpty,
            totalParticipants: totalConnected,
            remoteParticipants: Object.values(ps).filter(({ state }) => state && state !== 'disconnected')
        }
    });

    console.log('TeamLayout | render');
    return (
        <div className={styles.team_layout}>
            <SelfieView {...{ room, roomEmpty, totalParticipants }} />
            {!roomEmpty && <div className={styles.viewers}><Icon icon="viewers" />{totalParticipants}</div>}
            {remoteParticipants.map((rp) => <RemoteParticipant key={rp.identity} size={size} {...rp} />)}
        </div>
    )
}

export default React.memo(TeamLayout);