import React, { useState } from 'react';
import styles from './styles.module.css';
import Icon from '../../../../../../icon';
import SettingMenu from './setting-menu';

const DeviceControl = (props) => {
    const [open, toggle] = useState(false)

    return (
        <div className={styles[props.roomEmpty ? "setting_2" : "setting"]}>
            <button onClick={() => toggle(!open)}>
                <Icon className={styles.icon} icon="gear" />
            </button>
            {open && <SettingMenu {...{ ...props, toggle }} />}
        </div>
    )
}

export default React.memo(DeviceControl)