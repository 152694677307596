import React from 'react';
import styles from './styles.module.css';
import gameLogo from '../../images/wv-logo_dark.png';
import gameLogo2 from '../../images/FP Peace Game_H.png';

const Header = () => {
    return (
        <div className={styles.header}>
            <img className={styles.logo} alt="logo" src={gameLogo} />
            <img className={styles.logo_2} alt="logo" src={gameLogo2} />
        </div>
    )
}
export default React.memo(Header);