export default (state = {}, { type, payload }) => {
    if (type.endsWith("_REJECTED")) {
        type = "ERROR"
    }
    switch (type) {
        case "REMOVE-ALL-ERRORS": {
            return {}
        }
        case "REMOVE-ERROR": {
            delete state[payload]
            return Object.assign({}, state);
        }
        case "UPDATE-NETWORK-STATUS": {
            if (!navigator.onLine) {
                const index = Object.values(state).length
                return Object.assign({}, state, { [index]: { index, message: "Please check your internet connection." } });
            } else {
                return state;
            }
        }
        case "ERROR": {
            const index = Object.values(state).length
            if (payload?.response?.data?.message) {
                return Object.assign({}, state, { [index]: { index, message: payload.response.data.message } });
            } if (payload?.message) {
                return Object.assign({}, state, { [index]: { index, message: payload.message } });
            } if (payload?.message === "Network Error") {
                return Object.assign({}, state, { [index]: { index, message: payload.message } });
            } if (payload?.response?.status === 500) {
                return Object.assign({}, state, { [index]: { index, message: "request failed" } });
            } else {
                return Object.assign({}, state, { [index]: { message: JSON.stringify(payload), index } });
            }
        }
        default: {
            return state
        }
    }
}