import React, { useState } from 'react';
import styles from './styles.module.css';
import Icon from "../../../../../icon";
import SearchBar from './search-bar';
import UpdateMove from './update-move';
import CloseControl from '../close-control';
import ParticipantControl from './participant-control';
import SystemAnnouncement from './system-announcement';

const MeetingControl = () => {
    const [show, toggle] = useState(false);
    const [state, setState] = useState({ participant_control: false, move_control: false, search_bar: false, send_announcement: false });

    const handelClick = (type) => {
        setState((prevState) => ({ ...prevState, [type]: !prevState[type] }));
    }

    const toggleControls = () => {
        if (show) {
            return (
                <div className={styles.meeting_controls}>
                    <CloseControl onClick={() => toggle(false)} />
                    <div className={styles.controls}>
                        <button className={styles[state.search_bar && "green"]} onClick={() => handelClick("search_bar")}>Search Bar</button>
                        <button className={styles[state.move_control && "green"]} onClick={() => handelClick("move_control")}>Move Control</button>
                        <button className={styles[state.send_announcement && "green"]} onClick={() => handelClick("send_announcement")}>Send Announcement</button>
                        <button className={styles[state.participant_control && "green"]} onClick={() => handelClick("participant_control")}>Participant Control</button>
                    </div>
                </div>
            )
        } else {
            return (
                <button className={styles.meeting_controls_button} onClick={() => toggle(true)}>
                    <Icon className={styles.icon} icon="control" />
                </button>
            )
        }
    }

    return (
        <React.Fragment>
            {toggleControls()}
            <div className={styles.meeting_controls_wrapper}>
                {state.search_bar && <SearchBar {...{ handelClick }} />}
                {state.move_control && <UpdateMove {...{ handelClick }} />}
                {state.send_announcement && <SystemAnnouncement {...{ handelClick }} />}
                {state.participant_control && <ParticipantControl {...{ handelClick }} />}
            </div>
        </React.Fragment>
    )
}

export default React.memo(MeetingControl)