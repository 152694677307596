
function usePollingSwap (preferredTeam,setPreferredTeams,preferencesList,setPreference){
  
  const swapPreferredTeam = (dragIDX, hoverIDX, isAvailable, isSameColumn, blank) => {
    if (isAvailable && blank) {
      let hoveredTeam = preferencesList[hoverIDX]
      setPreferredTeams(preferredTeam => {
        const removeBlankTeam = [...preferredTeam]
        removeBlankTeam[dragIDX] = hoveredTeam
        return removeBlankTeam
      })
      setPreference(preferencesList => {
        const removeTeam = [...preferencesList]
        removeTeam.splice(hoverIDX, 1)
        return removeTeam
      })
    } else if (isSameColumn && !isAvailable) {
      const draggedTeam = preferredTeam[dragIDX]
      const hoveredTeam = preferredTeam[hoverIDX]
      setPreferredTeams(preferredTeam => {
        preferredTeam[dragIDX] = hoveredTeam
        return [...preferredTeam]
      })
      setPreferredTeams(preferredTeam => {
        preferredTeam[hoverIDX] = draggedTeam
        return [...preferredTeam]
      })
    }
    else {
      let draggedTeam = preferredTeam[dragIDX]
      let hoveredTeam = preferencesList[hoverIDX]
      if (!blank ) {
        if (isAvailable && !isSameColumn) {
          setPreferredTeams(preferredTeam => {
            preferredTeam[dragIDX] = hoveredTeam
            return [...preferredTeam]
          })
          setPreference(preferencesList => {
            preferencesList[hoverIDX] = draggedTeam
            return [...preferencesList]
          })
        }
        else if((isAvailable || !isAvailable) && !isSameColumn){
          draggedTeam = preferencesList[dragIDX]
          hoveredTeam = preferredTeam[hoverIDX]
          setPreferredTeams(preferredTeam => {
            preferredTeam[hoverIDX] = draggedTeam
            return [...preferredTeam]
          })
          setPreference(preferencesList => {
            preferencesList[dragIDX] = hoveredTeam
            return [...preferencesList]
          })
        }
      }
      else if(!isSameColumn) {
        const removedPreference = [...preferencesList]
        draggedTeam = removedPreference.splice(dragIDX, 1)[0]
        setPreference(removedPreference)
        setPreferredTeams(preferredTeam => {
          [...preferredTeam].splice(hoverIDX, 1)
          preferredTeam[hoverIDX] = draggedTeam
                return [...preferredTeam]
              })
      }
    }
          
  }
  
  
  return swapPreferredTeam
}
      
export default usePollingSwap