import React from 'react';
import styles from './styles.module.css';
import { useSelector, shallowEqual } from 'react-redux';

const HappeningNow = () => {
    const { image, title, description } = useSelector(({ moves: { moves, current } }) => {
        const [move, stage, sub_stage_id] = current
        const sub_stages = moves[move][stage].sub_stages[sub_stage_id]
        return Object.assign(sub_stages, sub_stages.guest)
    }, shallowEqual);

    console.log("HappeningNow");
    return (
        <div className={styles.wrapper}>
            <div className={styles.happening_now}>
                <p className={styles.title}>{title}</p>
                {description && description.split('&lt;br&gt;').map((paragraph, i) => <div key={i} className={styles.description}>{paragraph}</div>)}
                {image && <img className={styles.image} alt="" src={image} />}
            </div>
        </div>
    )
}

export default React.memo(HappeningNow);