import React, { useState } from 'react';
import styles from './styles.module.css';
import Moves from './move';
import Stages from './stage';
import SubStages from './sub-stage';
import Icon from "../../../../../../icon";
import CloseControl from '../../close-control';
import { useSelector, shallowEqual } from 'react-redux';
import useDrag from '../../../../../../../hooks/use-drag';

const UpdateMove = ({ handelClick }) => {
    const { elem, handleDrag } = useDrag(false);
    const [{ move_id, stage_id, type }, setStage] = useState({ type: "move" });
    const { loading, moves, current: [c_move, c_stage, c_sub_stage] } = useSelector(({ moves: list }) => {
        return {
            moves: list.moves,
            current: list.current,
            loading: list.updating_move
        }
    }, shallowEqual);

    return (
        <div ref={elem} className={styles.update_move} onMouseDown={handleDrag}>
            <CloseControl onClick={() => handelClick("move_control")} />
            {type === "move" && <Moves {...{ moves, setStage, c_move }} />}
            {type === "stage" && <Stages {...{ moves, move_id, setStage, c_move, c_stage }} />}
            {type === "sub_stage" && <SubStages {...{ moves, move_id, stage_id, setStage, c_move, c_stage, c_sub_stage }} />}
            {loading && <div className={styles.loading}><Icon className={styles.loading_icon} icon="loading" /></div>}
        </div>
    )
}

export default React.memo(UpdateMove)