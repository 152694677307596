import React from 'react';
import moment from 'moment';
import Avatar from '../avatar';
import styles from './styles.module.css';
import announcement_chime from './announcement_chime.mp3';

const MessageBox = ({ isUser, message, first_name, last_name, new_announcement, time_stamp, profile_picture, type }) => {
    const name = first_name.concat(' ', last_name);

    if (type === 'announcement') {
        const audio = new Audio(announcement_chime);
        audio.volume = 0.1
        if (new_announcement) audio.play()

        return (
            <div className={`${styles.chat_box_announcement}`}>
                <div className={`${styles.name_announcement} ${styles.name_other}`}>{name}</div>
                <div className={`${styles.content_announcement} ${styles.content_other}`}>{message}</div>
                <p className={`${styles.time_stamp_announcement} ${styles.time_stamp_other}`}>{moment(time_stamp).format("LT")}</p>
            </div>
        )
    } else {
        return (
            <div className={`${styles.message_container} ${styles[isUser && "is_user"]}`}>
                <Avatar className={styles.avatar} picture={profile_picture} />
                <div className={`${styles.chat_box}`}>
                    <div className={`${styles.name}`}>{name}</div>
                    <div className={`${styles.content}`}>{message}</div>
                </div>
                <p className={`${styles.time_stamp}`}>{moment(time_stamp).format("LT")}</p>
            </div>
        )
    }
}

export default React.memo(MessageBox)