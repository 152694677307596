import React, { useCallback, useEffect } from 'react';
import styles from './styles.module.css';
import Icon from '../../../icon';
import { createLocalAudioTrack } from 'shared-studios-twilio-video';

const AudioControl = ({ audioTrack, setAudioTrack, audioDevice }) => {

    const createAudioTrack = useCallback(() => {
        const constrains = { deviceId: { exact: audioDevice.deviceId } }
        createLocalAudioTrack(constrains)
            .then(setAudioTrack).catch((error) => {
                alert(error.message);
                console.error("MeetingControls | 2", error);
            });
    }, [setAudioTrack, audioDevice]);

    const handleAudio = () => {
        console.log("MeetingControls | handleAudio:", audioTrack)
        if (!audioTrack) {
            createAudioTrack()
        } else {
            audioTrack.stop();
            setAudioTrack(null);
        }
    }

    useEffect(createAudioTrack, [createAudioTrack]);

    return (
        <button onClick={handleAudio}>
            <Icon className={styles.icon} icon={audioTrack ? "audio-unmute" : "audio-mute"} />
        </button>
    )
}

export default React.memo(AudioControl)