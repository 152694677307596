import { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from "react-redux";

const useMeetingRoom = (room, meeting_type, selector) => {
    const dispatch = useDispatch()
    const state = useSelector(selector, shallowEqual);


    // setting up the room
    useEffect(() => {
        console.log('room useEffect will mount | participants track event');

        dispatch({
            type: "ADD-CURRENT-PARTICIPANTS",
            payload: { meeting_type, rps: Array.from(room.participants.values()) }
        });

        function trackSubscription(remoteTrack, remoteTrackPublication, rp) {
            console.log(`[${rp.identity}] | useMeetingRoom | trackSubscription`, remoteTrack, remoteTrackPublication, rp);
            dispatch({ type: "UPDATE-PARTICIPANTS-TRACK", payload: { rp, kind: remoteTrack.kind } })
        }

        function onTrackStarted(remoteTrack, rp) {
            console.log(`[${rp.identity}] | useMeetingRoom | onTrackStarted`, remoteTrack, rp);
            dispatch({ type: "UPDATE-PARTICIPANTS-TRACK", payload: { rp, kind: remoteTrack.kind } })
        }

        function onTrackSwitched(remoteTrack, remoteTrackPublication, rp) {
            console.log(`[${rp.identity}] | useMeetingRoom | onTrackSwitched`, remoteTrack, remoteTrackPublication, rp);
            dispatch({ type: "UPDATE-PARTICIPANTS-TRACK", payload: { rp, kind: remoteTrack.kind } })
        }

        function updateParticipantState(rp) {
            dispatch({ type: "UPDATE-PARTICIPANTS-STATUS", payload: { rp, meeting_type } })
        }

        // add participant's track event listeners
        room.on('trackStarted', onTrackStarted);
        room.on('trackSwitchedOn', onTrackSwitched);
        room.on('trackSwitchedOff', onTrackSwitched);
        room.on('trackUnsubscribed', trackSubscription);

        // add participant event listeners
        room.on('participantConnected', updateParticipantState);
        room.on('participantReconnected', updateParticipantState);
        room.on('participantReconnecting', updateParticipantState);
        room.on('participantDisconnected', updateParticipantState);
        return () => {
            // remove participant's track event listeners
            room.removeListener('trackStarted', onTrackStarted);
            room.removeListener('trackSwitchedOn', onTrackSwitched);
            room.removeListener('trackSwitchedOff', onTrackSwitched);
            room.removeListener('trackUnsubscribed', trackSubscription);

            // remove participant event listeners
            room.removeListener('participantConnected', updateParticipantState);
            room.removeListener('participantReconnected', updateParticipantState);
            room.removeListener('participantReconnecting', updateParticipantState);
            room.removeListener('participantDisconnected', updateParticipantState);
        }
    }, [room, meeting_type, dispatch]);


    useEffect(() => {
        console.log('useRoomStatus useEffect will mount');

        function onReconnected() {
            console.log("useRoomStatus | Reconnected to the room!")
            // setStatus(room.state)
        }
        function onReconnecting(error) {
            if (error.code === 53001) {
                console.log('useRoomStatus | Reconnecting your signaling connection!', error.message, room.state);
            } else if (error.code === 53405) {
                console.log('useRoomStatus | Reconnecting your media connection!', error.message, room.state);
            } else {
                console.log('useRoomStatus | Reconnecting', error);
            }
            // setStatus(room.state)
        }
        function onDisconnected(room, error) {
            // setStatus(room.state)
            if (error) {
                console.log('useRoomStatus | onDisconnected:', room, error);
            } else {
                console.log('useRoomStatus | onDisconnected | successfully disconnected from Room state:', { state: room.state, mediaRegion: room.mediaRegion });
            }
            room.localParticipant.tracks.forEach((publication) => {
                publication.track.stop();
                publication.unpublish();
            });
            dispatch({ type: "DISCONNECTED-FROM-ROOM" })
        }

        // add room event listeners
        room.on('reconnected', onReconnected);
        room.on('reconnecting', onReconnecting);
        room.on('disconnected', onDisconnected);

        console.log("useRoomStatus | addEventListener");
        console.log('useRoomStatus useEffect will mounted');
        return () => {
            console.log('useRoomStatus useEffect will unmount');
            room.disconnect();

            // remove room event listeners
            room.removeListener('reconnected', onReconnected);
            room.removeListener('reconnecting', onReconnecting);
            room.removeListener('disconnected', onDisconnected);

            console.log("useRoomStatus | removeListener");
            console.log('useRoomStatus useEffect unmounted');
        }
    }, [room, dispatch])

    return state
}

export default useMeetingRoom