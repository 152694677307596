import axios from '../../axios'

export const login = (data, event_id) => {
    return (dispatch) => {
        dispatch({ type: "LOGIN", payload: axios.post(`/user/${event_id}`, data) })
    }
}

export const fetchUserProfile = () => {
    return (dispatch) => {
        dispatch({
            type: "FETCH-PROFILE", payload: axios.get("eventwithstreamingurl").then((res) => {
                dispatch({ type: "FETCH-EVENT_FULFILLED", payload: res });
                return axios.get("/user/profile");
            })
        })
    }
}

export const fetchEvent = (event_id) => {
    return (dispatch) => {
        dispatch({ type: "FETCH-EVENT", payload: axios.get(`event/${event_id}`) });
    }
}

export const raiseHand = (raise_hand) => {
    return (dispatch) => {
        dispatch({ type: "RAISE-HAND", payload: axios.post(`user/raisehand`, { raise_hand }) })
    }
}
