import React, { useEffect, useRef, useCallback } from 'react';
import styles from './styles.module.css'
import Accordion from '../accordion';
import AgendaItem from './agenda-item';
import { getAgendas } from '../../actions/agendas';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';

const Agenda = () => {
    const dispatch = useDispatch();
    const parentScrollRef = useRef(null);
    const agendas = useSelector(({ agendas }) => agendas.items, shallowEqual);

    useEffect(() => dispatch(getAgendas()), [dispatch]);

    const scrollTo = useCallback((current_agenda) => {
        const parentOffset = parentScrollRef.current.offsetTop;
        const childeOffset = current_agenda.offsetTop;
        const offset = childeOffset - parentOffset;
        parentScrollRef.current.scrollTo(0, offset);
    }, []);

    console.log("Agenda");
    return (
        <Accordion title="Agenda">
            <div ref={parentScrollRef} className={styles.agenda_scroll}>
                {agendas.map((agenda, i) => {
                    return <AgendaItem key={i} scrollTo={scrollTo} {...agenda} />
                })}
            </div>
        </Accordion>
    )
}

export default React.memo(Agenda);