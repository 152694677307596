import React, { useEffect, useRef } from 'react';
import Modal from "../modal";
import Polling from "./polling";
import styles from './styles.module.css';
import { useSelector, shallowEqual } from 'react-redux';

const HappeningNow = ({ isFullScreen, title, description }) => {
    const state = useSelector(({ moves: list, teams, user }) => {
        const { moves, current } = list
        const [move, stage, sub_stage_id] = current
        const sub_stage = moves[move][stage].sub_stages[sub_stage_id]
        const { image, polling, polling_countdown_time } = sub_stage
        const submitted = user.preference.hasOwnProperty(`move_${move}`)
        const error_message = teams.error_message
        const loading = teams.loading
        const loading_message = teams.loading_message
        return {
            leader: user.leader,
            event_time: current[4],
            polling_countdown_time,
            image, polling, loading, submitted, error_message,
            loading_message,
            submitted_preference: user.preference[`move_${move}`],
            showModal: ((submitted || error_message || loading) && polling) || loading_message,
        }
    }, shallowEqual);

    const elm = useRef(null);
    const { image, polling, showModal } = state;
    const { leader, event_time, polling_countdown_time, loading_message } = state;
    const { submitted_preference, error_message, submitted, loading } = state;

    useEffect(() => {
        if (showModal) elm.current.scrollTo(0, 0);
    }, [showModal]);

    console.log("HappeningNow");
    return (
        <div className={`${styles.wrapper} ${styles[isFullScreen && 'fullScreen']}`}>
            <div className={styles.happening_now} ref={elm}>
                <p className={styles.title}>{title}</p>
                {description && description.split('&lt;br&gt;').map((paragraph, i) => <div key={i} className={styles.description}>{paragraph}</div>)}
                {image && <img className={styles.image} alt="" src={image} />}
                {polling && <Polling {...{ leader, submitted_preference, submitted, event_time, polling_countdown_time }} />}
            </div>
            {showModal && <Modal {...{ submitted_preference, error_message, loading_message, submitted, loading }} />}
        </div>
    )
}

export default React.memo(HappeningNow);