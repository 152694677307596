import React, { useEffect } from 'react'
import styles from './styles.module.css';
import Icon from "../../icon";
import Accordion from '../accordion';
import ResourceItem from "./resource-item";
import { fetchResources } from '../../actions/resources';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

const Resources = () => {
    const dispatch = useDispatch();
    const resources = useSelector(({ resources, moves: { current } }) => {
        const [current_move, current_stage] = current
        return resources.filter(({ move, stage }) => {
            return move < current_move || (move === current_move && stage <= current_stage)
        })
    }, shallowEqual);

    useEffect(() => dispatch(fetchResources()), [dispatch]);

    console.log("Resources");
    return (
        <Accordion title="Resources" collapsible={true} >
            <div className={styles.resources}>
                {resources.length ? resources.map((resource, i) => <ResourceItem key={i} {...resource} />) :
                    <div className={styles.no_resource}>
                        <Icon className={styles.icon} icon="info" />
                        <span>No resources available at the moment</span>
                    </div>}
            </div>
        </Accordion>
    )
}

export default React.memo(Resources);