import React, { useEffect, useState } from 'react';
import styles from './styles.module.css';

const TrackStatus = ({ track, type }) => {
    const [isMuted, setMuted] = useState(track?.isMuted);

    useEffect(() => {
        if ('track' in track) {
            const mediaTrack = track.track.mediaStreamTrack;
            function onmute(e) {
                console.log(`TrackStatus | onTrackMute`, e.target);
                setMuted(true);
            }
            function onunmute(e) {
                console.log(`TrackStatus | onTrackUnmute`, e.target);
                setMuted(false);
            }
            mediaTrack.addEventListener('mute', onmute);
            mediaTrack.addEventListener('unmute', onunmute);
            return () => {
                mediaTrack.removeEventListener('mute', onmute);
                mediaTrack.removeEventListener('unmute', onunmute);
            }
        }
    }, [track]);

    return (
        <div>
            <b>{type}:</b>
            <p>readyState: <span className={styles[track?.readyState === 'live' ? "green" : "red"]}>{track?.readyState}</span></p>
            <p>isMuted: <span className={styles[isMuted ? "red" : "green"]}>{isMuted?.toString()}</span></p>
            <p>isEnabled: <span className={styles[track?.isEnabled ? "green" : "red"]}>{track?.isEnabled?.toString()}</span></p>
            <p>isStarted: <span className={styles[track?.isStarted ? "green" : "red"]}>{track?.isStarted?.toString()}</span></p>
            <p>isSubscribed: <span className={styles[track?.isSubscribed ? "green" : "red"]}>{track?.isSubscribed?.toString()}</span></p>
            <p>isSwitchedOff: <span className={styles[track?.isSwitchedOff ? "red" : "green"]}>{track?.isSwitchedOff?.toString()}</span></p>
        </div>
    )
}

export default React.memo(TrackStatus);