import React from 'react';
import styles from './styles.module.css';
import Icon from '../../../../../../icon';
import PublishButton from './publish-button'

const SubStages = ({ moves, move_id, stage_id, setStage, c_move, c_stage, c_sub_stage }) => {
    return (
        <React.Fragment>
            <div className={styles.move_header}>
                <button onClick={() => setStage({ type: "move" })}>Move {move_id + 1}</button>
                <Icon className={styles.move_header_arrow} icon="arrow" />
                <button onClick={() => setStage({ type: "stage", move_id })}>Stage {stage_id + 1}</button>
                <Icon className={styles.move_header_arrow} icon="arrow" />
                <div>Sub Stage</div>
            </div>
            <div className={styles.move_list}>
                {moves[move_id][stage_id]["sub_stages"].map(({ announcement, generate_results, polling }, i) => (
                    <div className={styles.move_item} key={i}>
                        <span title={announcement}>{(c_move === move_id && c_stage === stage_id && c_sub_stage === i) && "✔"} {i + 1}. {announcement}</span>
                        <PublishButton {...{ move_id, stage_id, sub_stage_id: i, generate_results, polling }} />
                    </div>
                ))}
            </div>
        </React.Fragment>
    )
}

export default React.memo(SubStages)