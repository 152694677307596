import React, { useEffect } from "react";
import styles from "./styles.module.css";
import Header from "./header";
import Loading from "../loading";
import Sidebar from "../sidebar";
import CentralPanel from "./central-panel";
import { fetchMoves } from "../actions/moves";
import { useDispatch, useSelector, shallowEqual } from "react-redux";

const LandingPage = () => {
    const dispatch = useDispatch();
    const { loading, message } = useSelector(({ moves }) => {
        return {
            loading: moves.loading,
            message: moves.message,
        };
    }, shallowEqual);

    useEffect(() => dispatch(fetchMoves()), [dispatch]);

    if (loading) {
        return <Loading />;
    } else if (!loading && message) {
        return <Loading message={message} />;
    } else {
        return (
            <div className={styles.public_page}>
                <Header />
                <Sidebar />
                <CentralPanel />
            </div>
        );
    }
};

export default React.memo(LandingPage);
