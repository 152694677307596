import React, { useEffect } from 'react'
import styles from './styles.module.css'
import UserType from "./user-type";
import Accordion from '../accordion';
import ParticipantType from "./participant-type";
import { getTeams } from "../../actions/teams";
import { useSelector, useDispatch, shallowEqual } from 'react-redux';

const Teams = () => {
    const dispatch = useDispatch();
    const { teams, type } = useSelector(({ teams: { list }, user }) => {
        if (user.type === "producer") {
            return {
                type: user.type,
                teams: list.sort((a, b) => {
                    if (a.first_name > b.first_name) return -1
                    if (b.first_name > a.first_name) return 1
                    return 0
                }).sort((a) => {
                    if (a.status === "online") return -1
                    return 0
                }).sort((a) => {
                    if (a.type === "guest") return -1
                    return 0
                })
            }
        } else {
            return { teams: list, type: user.type }
        }
    }, shallowEqual);

    useEffect(() => dispatch(getTeams()), [dispatch]);

    console.log("Teams");
    return (
        <Accordion title="Participants">
            <div className={styles.participants}>
                {type === "producer" && teams.map((team, i) => <UserType key={i} {...team} />)}
                {type !== "producer" && teams.map((team, i) => <ParticipantType key={i} {...team} />)}
            </div>
        </Accordion>
    )
}

export default React.memo(Teams);