import React from 'react';
import styles from './styles.module.css';
import useCountTo from "../../../../../hooks/use-count-to";

const SubmitPreferenceButton = ({ submitted, event_time, polling_countdown_time }) => {
    const { timeLeft } = useCountTo(event_time, polling_countdown_time, true)
    return (
        <div className={`${styles.submit_button} ${(!timeLeft || submitted) ? styles.submit_button_end : null}`}>
            <span className={styles.submit_button_text}>{submitted ? "Submitted" : !timeLeft ? "Time Elapsed" : "Submission time"}</span>
            {(timeLeft && !submitted) && <p className={`${styles.submit_button_time}`}>{timeLeft.format("hh:mm:ss", { trim: false })}</p>}
        </div>
    )
}

export default React.memo(SubmitPreferenceButton)