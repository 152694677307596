import React from 'react';
import styles from './styles.module.css';
import { useSelector, shallowEqual } from 'react-redux';
import useCountTo from '../../../../hooks/use-count-to';

const Announcement = ({ isFullScreen, announcement }) => {
    const { time_stamp, countdown_time } = useSelector(({ moves: list }) => {
        const { moves, current } = list
        const [move, _stage] = current
        const stage = moves[move][_stage]
        return {
            time_stamp: current[3],
            countdown_time: stage.countdown_time,
        }
    }, shallowEqual);

    const { timeLeft, timeStatus, colorStatus } = useCountTo(time_stamp, countdown_time);

    console.log("Announcement:");
    return (
        <div className={`${styles.announcement} ${styles[colorStatus]} ${styles[isFullScreen && 'fullScreen']}`}>
            <p className={styles.title}>{announcement}</p>
            {countdown_time && <p className={`${styles.timer} ${styles[timeStatus]}`}>
                {timeLeft ? timeLeft.format("hh:mm:ss", { trim: false }) : "00:00:00"}
            </p>}
        </div>
    )
}

export default React.memo(Announcement)