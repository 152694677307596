import { useState } from 'react';
import usePublishTracks from './use-publish-tracks';
import createGridViewTrack from '../grid-view-track';

const useGetDisplayTracks = (lp) => {
    const [tracks, setTracks] = useState([]);
    const [publishedTracks, isPublished] = usePublishTracks(lp, tracks);

    const stopTracks = () => {
        if (tracks) {
            setTracks([]);
            tracks.forEach((track) => track.stop());
        }
    }

    const handleDisplaySharing = () => {
        if (!isPublished) {
            const constrains = {
                frameRate: 30,
                video: { cursor: { exact: "none" } },
                audio: { echoCancellation: true, noiseSuppression: true }
            }
            navigator.mediaDevices.getDisplayMedia(constrains)
                .then((stream) => {
                    setTracks(stream.getTracks())
                }).catch((error) => {
                    alert(error.message)
                    console.log(error)
                })
        } else {
            stopTracks();
        }
    }

    const handleGridViewSharing = () => {
        if (!isPublished) {
            const gridViewTrack = createGridViewTrack().start();
            setTracks(gridViewTrack);
        } else {
            stopTracks();
        }
    }

    const handelScreenSharing = (type) => {
        if (type === "screen") {
            handleDisplaySharing();
        }
        if (type === "grid") {
            handleGridViewSharing();
        }
    }

    return [publishedTracks, isPublished, handelScreenSharing]
}

export default useGetDisplayTracks;