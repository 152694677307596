export default (state = { messages: [] }, { type, payload }) => {
  switch (type) {
    case "GET_MESSAGE_FULFILLED": {
      const { data } = payload
      return Object.assign({}, state, { messages: [...data].sort((a, b) => a.time_stamp.localeCompare(b.time_stamp)) })
    }
    case "NEW-MESSAGE": {
      if(payload.type === "announcement") payload.new_announcement = true
      return Object.assign({}, state, { messages: [...state.messages, payload] })
    }
    case "SEND_MESSAGE_FULFILLED": {
      const { data } = payload
      return Object.assign({}, state, { messages: [...state.messages, data] })
    }
    default: {
      return state
    }
  }
}