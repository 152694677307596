import React from 'react';
import styles from "./styles.module.css"
import Icon from '../../../../../icon'

const StaticPreferences = ({ name }) => {
    return (
        <div className={styles.rank_item}>
            <Icon className={styles.rank_item_icon} icon="rank-team" />  <p className={styles.rank_item_team_name}>{name} </p>
        </div>
    )
}


export default React.memo(StaticPreferences)