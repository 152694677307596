import React from 'react';
import styles from './styles.module.css';
import Icon from '../../../../../../icon';
import PublishButton from './publish-button';

const Stages = ({ moves, move_id, setStage, c_move, c_stage }) => {

    const handleClick = (i, sub_stages) => {
        if (sub_stages > 1) setStage({ type: "sub_stage", move_id, stage_id: i })
    }

    return (
        <React.Fragment>
            <div className={styles.move_header}>
                <button onClick={() => setStage({ type: "move" })}>Move {move_id + 1}</button>
                <Icon className={styles.move_header_arrow} icon="arrow" />
                <div>Stage</div>
            </div>
            <div className={styles.move_list}>
                {moves[move_id].map(({ name, sub_stages, meeting_type, raise_hand }, i) => (
                    <div key={i} className={styles.move_item} onClick={() => handleClick(i, sub_stages.length)}>
                        <span title={name}>{(c_move === move_id && c_stage === i) && "✔"} {i + 1}. {name}</span>
                        {sub_stages.length === 1 ? <PublishButton {...{ move_id, stage_id: i, sub_stage_id: 0, meeting_type, raise_hand }} /> : <Icon className={styles.arrow} icon="arrow" />}
                    </div>
                ))}
            </div>
        </React.Fragment>
    )
}

export default React.memo(Stages)