import { useState, useLayoutEffect, useRef } from 'react';

const useRatioHeight = (widthRatio, heightRatio, isFullScreen) => {
    const elem = useRef();
    const [height, setHeight] = useState(0);

    useLayoutEffect(() => {
        const element = elem.current
        const resizeObserver = new ResizeObserver(() => {
            if (isFullScreen) {
                setHeight(null);
            } else {
                setHeight(element.clientWidth / (widthRatio / heightRatio));
            }
        })
        resizeObserver.observe(element);
        return () => {
            resizeObserver.disconnect(element);
        }
    }, [elem, widthRatio, heightRatio, isFullScreen])

    return [elem, height]
}

export default useRatioHeight;