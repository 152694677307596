import React from "react";
import styles from "./styles.module.css";
import Header from "./header";
import SelfieView from "./selfie-view";

const TestPage = () => {
    return (
        <div className={styles.test_page}>
            <Header />
            <SelfieView />
        </div>
    );
};

export default React.memo(TestPage);
