import './index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import store from "./redux/store";
// import LogRocket from 'logrocket';
import { Provider } from "react-redux";
import TestPage from './components/test-page';
import FourOFourPage from './components/404-page';
import ValidPage from './components/page-validation';
import BrowserCheck from './components/browser-check';
import NetWorkStatus from './components/network-status';
import Authentication from './components/authentication';
import { Route, BrowserRouter, Switch } from 'react-router-dom';

const App = () => {
    return (
        <BrowserCheck>
            <Provider store={store}>
                <NetWorkStatus>
                    <BrowserRouter>
                        <Switch>
                            <Route exact path="/test" component={TestPage} />
                            <Authentication exact path="/:event_id" component={ValidPage} />
                            <Route component={FourOFourPage} />
                        </Switch>
                    </BrowserRouter>
                </NetWorkStatus>
            </Provider>
        </BrowserCheck>
    )
}

// LogRocket.init('gail29/fp-client');

ReactDOM.render(<App />, document.getElementById('root'))