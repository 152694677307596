import React, { useEffect } from "react";
import styles from "./styles.module.css";
import Header from "./header";
import Sidebar from "../sidebar";
import Loading from "../loading";
// import LogRocket from "logrocket";
import CentralPanel from "./central-panel";
import { fetchMoves } from "../actions/moves";
import { useDispatch, useSelector, shallowEqual } from "react-redux";

const LandingPage = () => {
    const dispatch = useDispatch();
    const { loading, message } = useSelector(({ moves }) => {
        // const { email, first_name, last_name, team_name, loading, message, } = useSelector(({ moves, user }) => {
        return {
            // email: user.email,
            loading: moves.loading,
            message: moves.message,
            // last_name: user.last_name,
            // team_name: user.team_name,
            // first_name: user.first_name,
        };
    }, shallowEqual);
    // LogRocket.identify(email, { name: `${first_name} ${last_name}`, email, team_name });


    useEffect(() => dispatch(fetchMoves()), [dispatch]);

    console.log("LandingPage")

    if (loading) {
        return <Loading />;
    } else if (!loading && message) {
        return <Loading message={message} />;
    } else {
        return (
            <div className={styles.landing_page}>
                <Header />
                <Sidebar />
                <CentralPanel />
            </div>
        );
    }
};

export default React.memo(LandingPage);
