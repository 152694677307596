import React from 'react';
import styles from './styles.module.css';
import Icon from '../../../../icon';
import ShareScreen from './share-screen';
import RemoteParticipant from '../remote-participant';
import useMeetingRoom from '../../../../../hooks/use-meeting-room';

const GroupLayout = ({ room }) => {
    const { remoteParticipants, size, screenSharing, totalParticipants } = useMeetingRoom(room, "group", ({ meeting }) => {
        const { participants: ps, size, totalConnected, screenSharing } = meeting
        return {
            size,
            screenSharing,
            totalParticipants: totalConnected,
            remoteParticipants: Object.values(ps).filter(({ show, state, type }) => {
                return show && state && state !== 'disconnected' && type !== "producer" && type !== "streamer"
            })
        }
    });

    console.log('GroupLayout | render');
    return (
        <div className={styles.group_layout}>
            <div className={styles.viewers}><Icon icon="viewers" />{totalParticipants}</div>
            {screenSharing && <ShareScreen remoteParticipants={remoteParticipants} admin={screenSharing} />}
            {!screenSharing && remoteParticipants.map((rp) => <RemoteParticipant key={rp.identity} size={size} {...rp} />)}
        </div>
    )
}

export default React.memo(GroupLayout);