import React from 'react';
import styles from './styles.module.css'
import Transitions from './transitions';
import Announcement from './announcement';
import HappeningNow from './happening-now';

const CentralPanel = () => {
    return (
        <div className={styles.central_panel}>
            <Announcement />
            <Transitions />
            <HappeningNow />
        </div>
    )
}

export default React.memo(CentralPanel)