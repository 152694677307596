import React from 'react';
import styles from "./styles.module.css";
import { updateMove } from '../../../../../../actions/moves';
import { useDispatch } from 'react-redux';

const PublishButton = ({ move_id: current_move, stage_id: current_stage, sub_stage_id: current_sub_stage, generate_results, polling, meeting_type, raise_hand }) => {
    const dispatch = useDispatch();

    const handlePublish = () => {
        const data = { current_move, current_stage, current_sub_stage }
        dispatch(updateMove(data, generate_results, polling, meeting_type, raise_hand));
    }

    return <button className={styles.publish_button} onClick={handlePublish}>send</button>
}

export default React.memo(PublishButton);