export default (state = [], { type, payload }) => {
    switch (type) {
        case "FETCH-PARTICIPANTS_PENDING": {
            return [];
        }
        case "FETCH-RAISED-HANDS_FULFILLED": {
            return payload.data.sort((a, b) => new Date(a.raise_hand_time) - new Date(b.raise_hand_time));
        }
        case "LOWER-HAND_FULFILLED": {
            return state.filter(({ email }) => email !== payload.data.email);
        }
        case "RAISE-HAND": {
            return [...state, payload];
        }
        case "LOWER-HAND": {
            return state.filter(({ email }) => email !== payload.email);
        }
        default: {
            return state
        }
    }
}