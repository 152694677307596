import React from 'react';
import styles from './styles.module.css';
import Icon from "../../../icon";

const ResourceItem = ({ title, type, link }) => {
    console.log("ResourceItem");
    return (
        <a className={styles.resource_items} href={link} target="_blank" rel="noOpener noReferrer">
            <Icon className={styles.icon} icon={type} />
            <span className={styles.title}>{title}</span>
        </a>
    )
}

export default React.memo(ResourceItem);