import { combineReducers } from "redux"

import chat from './chat-reducer'
import user from './user-reducer'
import moves from './moves-reducer'
import teams from './teams-reducer'
import errors from './error-reducer'
import meeting from './meeting-reducer'
import agendas from './agendas-reducer'
import resources from './resources-reducer'
import raised_hands from './raised-hands-reducer'

const appReducer = combineReducers({
    chat,
    user,
    moves,
    teams,
    errors,
    meeting,
    agendas,
    resources,
    raised_hands,
})


export default (state, action) => {
    if (action.type === "LOGGED-OUT") {
        state = { user: { event_id: state.user.event_id } }
    }
    return appReducer(state, action);
};

