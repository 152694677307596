import axios from '../../axios'


export const sendMessage = (message) => {
    return (dispatch) => {
        dispatch({ type: "SEND_MESSAGE", payload: axios.post('chat', message) })
    }
}

export const sendAnnouncement = (message) => {
    return (dispatch) => {
        dispatch({ type: "SEND-ANNOUNCEMENT", payload: axios.post('chat/announcement', message) })
    }
}

export const getMessage = () => {
    return (dispatch) => {
        dispatch({ type: "GET_MESSAGE", payload: axios.get('chat') })
    }
}