import React, { useEffect } from 'react';
import styles from './styles.module.css';
import RaiseHandItem from './raise-hand-item';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { fetchRaisedHands } from '../../../../../../actions/raised-hand';

const RaiseHandNotification = () => {
    const dispatch = useDispatch();
    const raisedHands = useSelector(({ raised_hands }) => raised_hands, shallowEqual);

    useEffect(() => dispatch(fetchRaisedHands()), [dispatch]);

    return (
        <div className={styles.wrapper}>
            {raisedHands.map((raised_hand, i) => <RaiseHandItem key={i} {...raised_hand} />)}
        </div>
    );
};

export default React.memo(RaiseHandNotification);