import React from 'react';
import styles from './styles.module.css';
import ErrorMessage from './error-message';
import VideoTransition from './video-transition';
import MeetingTransition from './meeting-transition';
import useRatioHeight from '../../../hooks/use-ratio-height';
import { useDispatch, useSelector, shallowEqual } from "react-redux";

const Meeting = () => {
    const dispatch = useDispatch();
    const [elem, height] = useRatioHeight(16, 9, false, 0);
    const { raise_hand, meeting_type, allowed_to_Play, ...stage } = useSelector(({ moves: list, meeting }) => {
        const { moves, current } = list
        const [move, _stage] = current
        const stage = moves[move][_stage]
        return {
            stage_type: stage.type,
            image_src: stage.image_src,
            video_src: stage.video_src,
            raise_hand: stage.raise_hand,
            meeting_type: stage.meeting_type,
            allowed_to_Play: meeting.allowedToPlay,
            transition_type: stage.transition_type,
            sponsor_image_src: stage.sponsor_image_src,
            sponsor_video_src: stage.sponsor_video_src,
        }
    }, shallowEqual);

    const meetingType = () => {
        if (stage.stage_type === "transition") {
            if (stage.transition_type === "video-transition") {
                return <VideoTransition src={stage?.sponsor_video_src || stage?.video_src} />
            } else if (stage.transition_type === "image-transition") {
                return <img className={styles.transition} src={stage?.sponsor_image_src || stage?.image_src} alt="" />
            } else {
                return <ErrorMessage type="error" message={`Invalid transition type ${stage.transition_type}.`} />
            }
        } else if (stage.stage_type === "meeting") {
            if (meeting_type === "group") {
                if (!allowed_to_Play) {
                    const error = {
                        type: "error",
                        ok: () => dispatch({ type: "ALLOWED-TO-PLAY", payload: true }),
                        message: "Your browser window became inactive. Please click ok to activate the window and join the meeting.",
                    }
                    return <ErrorMessage {...error} />
                } else {
                    return <MeetingTransition />
                }
            } else if (stage.sponsor_video_src) {
                return <VideoTransition src={stage?.video_src || stage?.sponsor_video_src} />
            } else if (stage.sponsor_image_src) {
                return <img className={styles.transition} src={stage?.sponsor_image_src || stage?.image_src} alt="" />
            } else {
                return <ErrorMessage type="error" message="No meeting type, sponsor video or image found." />
            }
        } else {
            return <ErrorMessage type="error" message={`Invalid stage type ${stage.stage_type}.`} />
        }
    }

    return (
        <div ref={elem} className={styles.meeting_container} style={{ height }}>
            {meetingType()}
        </div>
    )
}

export default React.memo(Meeting);