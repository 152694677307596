import React from 'react';
import styles from './styles.module.css';
import UserAvatar from './user-avatar';
import gameLogo from '../../images/wv-logo_dark.png';
import gameLogo2 from '../../images/FP Peace Game_H.png';
import { useSelector, shallowEqual } from 'react-redux';

const Header = () => {
    const { name, profile, team_name, sponsor_logos } = useSelector(({ user }) => {
        const { first_name, last_name, profile_picture, team_name, sponsor_logos } = user;
        return {
            team_name,
            sponsor_logos,
            profile: profile_picture,
            name: first_name.concat(" ", last_name),
        };
    }, shallowEqual);

    console.log("Header");
    return (
        <div className={styles.header}>
            <img className={styles.logo} alt="logo" src={gameLogo} />
            <img className={styles.logo_2} alt="logo" src={gameLogo2} />
            <div className={styles.inner_logos}>
                {sponsor_logos.map((logo, index) => <img key={index} alt={`logo_${index}`} src={logo} />)}
            </div>
            <UserAvatar {...{ name, profile, team_name }} />
        </div>
    )
}
export default React.memo(Header);