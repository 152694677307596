import React, { useState } from 'react';
import styles from './styles.module.css';
import SelectInput from './select-input';
import VideoControl from './video-control';
import AudioControl from './audio-control';

const MeetingControls = ({ audioTrack, setAudioTrack, videoTrack, setVideoTrack }) => {
    const [videoDevice, setVideoDevice] = useState(null);
    const [audioDevice, setAudioDevice] = useState(null);

    return (
        <div className={`${styles.meeting_controls} ${styles.button_big}`}>
            { audioDevice && <AudioControl {...{ audioTrack, setAudioTrack, audioDevice }} />}
            { videoDevice && <VideoControl {...{ videoTrack, setVideoTrack, videoDevice }} />}
            <SelectInput {...{ videoDevice, audioDevice, setVideoDevice, setAudioDevice }} />
        </div>
    )
}

export default React.memo(MeetingControls);