import { useState, useEffect } from 'react';

const usePublishTracks = (lp, tracks) => {
    const [isPublished, setPublished] = useState(false);
    const [publishedTracks, setPublishedTracks] = useState({});

    useEffect(() => {
        if (tracks.length) {
            lp.publishTracks(tracks, { priority: 'high' })
                .then((publication) => {
                    console.log('usePublishTracks | The LocalScreenSharingTrack was successfully published with priority', publication);
                    const tracks = {}
                    publication.forEach(({ track }) => tracks[track.kind] = track);
                    setPublishedTracks(tracks);
                    setPublished(true);
                }).catch((error) => {
                    console.error("usePublishTracks | 1", error);
                    alert(`Sorry could not publish the screen sharing to the room: ${error.message}`)
                })
            return () => {
                lp.tracks.forEach((p) => p.unpublish());
                setPublishedTracks({});
                setPublished(false);
                console.log("unpublish:", tracks);
            }
        }
    }, [lp, tracks]);

    return [publishedTracks, isPublished]
}

export default usePublishTracks;