import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchCurrentMove } from '../actions/moves';

const NetworkStatus = ({ children }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        const updateNetworkStatue = () => {
            dispatch({ type: "UPDATE-NETWORK-STATUS" });
            if (navigator.onLine) dispatch(fetchCurrentMove());
        }
        window.addEventListener('online', updateNetworkStatue);
        window.addEventListener('offline', updateNetworkStatue);
        return () => {
            window.removeEventListener('online', updateNetworkStatue);
            window.removeEventListener('offline', updateNetworkStatue);
        }
    }, [dispatch]);

    return children
}

export default React.memo(NetworkStatus);
