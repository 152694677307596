import React from 'react';
import styles from './styles.module.css';

const Moves = ({ moves, setStage, c_move }) => {

    return (
        <React.Fragment>
            <div className={styles.move_header}>
                <div>Move</div>
            </div>
            <div className={styles.move_list}>
                {moves.map((_, i) => {
                    return (
                        <div key={i} className={styles.move_item} onClick={() => setStage({ type: "stage", move_id: i })}>
                            {c_move === i && "✔"} Move {i + 1}
                        </div>
                    )
                })}
            </div>
        </React.Fragment>
    )
}

export default React.memo(Moves);