import React, { useRef, useEffect } from 'react';
import styles from './styles.module.css';
import Icon from '../../../icon';

const AgendaItem = ({ scrollTo, duration, title, isCurrent }) => {
    const elem = useRef(null)

    useEffect(() => {
        if (isCurrent) {
            scrollTo(elem.current)
        }
    }, [isCurrent, scrollTo])

    console.log("AgendaItem");
    return (
        <div ref={elem} className={`${styles.agenda_item}  ${isCurrent && styles.active_agenda}`}>
            <Icon icon={"arrow-2"} className={styles.icon} />
            <p className={styles.title}>{title}</p>
            {duration && <p className={styles.duration}>{duration}</p>}
        </div>
    )
}

export default React.memo(AgendaItem);