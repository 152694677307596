import { useEffect, useState, useRef } from 'react';

const usePlayLocalTrack = (track) => {
    const ref = useRef();
    const [isPlaying, setPlaying] = useState(false);

    useEffect(() => {
        if (track) {
            const elem = track.attach(ref.current);
            const onCanPlay = () => elem.play();
            const onPlay = () => setPlaying(true);
            elem.addEventListener('play', onPlay);
            elem.addEventListener('canplay', onCanPlay);
            console.log(`LocalParticipant | attached ${track.kind} track`);
            return () => {
                track.stop();
                track.detach();
                setPlaying(false);
                elem.removeEventListener('play', onPlay);
                elem.removeEventListener('canplay', onCanPlay);
                console.log(`LocalParticipant | detached ${track.kind} track`);
            };
        }
    }, [ref, track]);

    return [isPlaying, ref]
}

export default usePlayLocalTrack;