import React from 'react';
import styles from './styles.module.css';
import Preference from './preferences/static'
import SubmitPreferenceButton from './submit-preference-button-non-leader'

const NonLeaderPolling = ({ preferences, event_time, submitted, polling_countdown_time }) => {
    const half = Math.round(preferences.length / 2);
    const leftColumn = preferences.slice(0, half);
    const rightColumn = preferences.slice(half);

    return (
        <div className={styles.rank_teams_none}>
            <SubmitPreferenceButton  {...{ polling_countdown_time, submitted, event_time }} />
            <p className={styles.available_team_title}>Available options</p>
            <div className={styles.available_team_non_leader}>
                <div className={styles.left_column}>
                    {leftColumn.map((team, i) => <Preference key={i} {...team} />)}
                </div>
                <div className={styles.right_column}>
                    {rightColumn.map((team, i) => <Preference key={i} {...team} />)}
                </div>
            </div>
        </div>
    )
}

export default React.memo(NonLeaderPolling)
