import React from 'react';
import styles from './styles.module.css'
import Error from './error';
import { useTransition, animated } from 'react-spring';
import { useSelector, shallowEqual } from 'react-redux';

const Errors = () => {
    const errors = useSelector((state) => Object.values(state.errors), shallowEqual);
    const transitions = useTransition(errors, (item) => item.index, {
        from: { opacity: 0, transform: "translateX(-100%)" },
        enter: { opacity: 1, transform: "translateX(0%)" },
        leave: { opacity: 0, transform: "translateX(-100%)" },
    })

    console.log('Errors')

    return (
        <div className={styles.errors}>
            {transitions.map(({ item, key, props }) => {
                return item && <animated.div key={key} style={props} className={styles.error}>
                    <Error {...item} />
                </animated.div>
            })}
        </div>
    )
}

export default React.memo(Errors)
