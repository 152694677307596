import React from 'react';
import styles from './styles.module.css';
import Icon from "../../../../../icon";

const CloseControl = ({ onClick }) => {
    return (
        <button className={styles.close_control} onClick={onClick}>
            <Icon icon="cross" className={styles.close} />
        </button>
    )
}

export default React.memo(CloseControl);