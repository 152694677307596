import React, { useEffect, useState } from 'react';
import styles from './styles.module.css';
import Icon from '../../../icon';
import SettingMenu from './setting-menu';

const SelectInput = ({ videoDevice, audioDevice, setVideoDevice, setAudioDevice }) => {
    const [open, toggle] = useState(false)
    const [videoInputs, setVideoInputs] = useState([])
    const [audioInputs, setAudioInputs] = useState([])

    const promptDevice = async () => {
        return await navigator.mediaDevices.getUserMedia({
            audio: true,
            video: {
                width: { min: 1280, max: 4096 },
                height: { min: 720, max: 2160 },
                frameRate: { min: 30, max: 60 }
            }
        })
    }

    useEffect(() => {
        promptDevice().then((res) => {
            if (res.id) {
                console.log('getUser w/ id', res)
                navigator.mediaDevices.enumerateDevices().then((mediaDevices) => {
                    mediaDevices.forEach((device) => { if (device.kind === 'videoinput') console.log('device: ', device) })
                    setVideoInputs(mediaDevices.filter((mediaDevice) => mediaDevice.kind === 'videoinput'))
                    setAudioInputs(mediaDevices.filter((mediaDevice) => mediaDevice.kind === 'audioinput'))
                }).catch((err) => {
                    console.log('err (enumerateDevices)', err)
                });
            } else {
                console.log('res (getUser)', res)
            }
        }).catch((err) => {
            console.log('err (getUser)', err)
        })
    }, []);

    const handleChange = ({ target: { name, value } }) => {
        if (name === "videoInput") {
            localStorage.setItem("videoInput", value)
            setVideoDevice(value)
        }
        if (name === "audioInput") {
            localStorage.setItem("audioInput", value)
            setAudioDevice(value)
        }
    }

    useEffect(() => {
        const videoInput = localStorage.getItem("videoInput");
        if (videoInput) {
            setVideoDevice(videoInput);
        } else if (videoInputs.length) {
            setVideoDevice(videoInputs[0].deviceId);
            localStorage.setItem("videoInput", videoInputs[0].deviceId)
        }
    }, [setVideoDevice, videoInputs]);

    useEffect(() => {
        const audioInput = localStorage.getItem("audioInput");
        if (audioInput) {
            setAudioDevice(audioInput);
        } else if (audioInputs.length) {
            setAudioDevice(audioInputs[0].deviceId)
            localStorage.setItem("audioInput", audioInputs[0].deviceId)
        }
    }, [setAudioDevice, audioInputs]);

    return (
        <div className={styles.setting}>
            {open && <SettingMenu {...{ handleChange, videoInputs, audioInputs, videoDevice, audioDevice }} />}
            <button onClick={() => toggle(!open)}>
                <Icon className={styles.icon} icon="gear" />
            </button>
        </div>
    )
}

export default React.memo(SelectInput)