import React, { useState } from 'react';
import styles from './styles.module.css';
import Icon from "../../../icon";
import ChatWindow from "./chat-window";
import { sendMessage } from "../../../actions/chat";
import { useSelector, useDispatch, shallowEqual } from "react-redux";


const TeamChat = ({ isFullScreen }) => {
    const dispatch = useDispatch();
    const [text, setText] = useState("");
    const { email, team_name } = useSelector(({ user }) => {
        return {
            email: user.email,
            team_name: user.team_name
        }
    }, shallowEqual);

    const handleClick = () => {
        if (text.trim().length > 0) {
            dispatch(sendMessage({ message: text.trim() }));
            setText("");
        }
    }

    const textSubmit = (e) => {
        if (e.keyCode === 13 && !e.shiftKey) {
            e.preventDefault();
            handleClick();
        }
    }

    const handleChange = (e) => {
        if (e.target.value.length <= 700) {
            setText(e.target.value)
        }
    }

    console.log("TeamChat");
    if (!team_name) {
        return (
            <div className={`${styles.team_chat} ${styles.no_team_chat} ${styles[isFullScreen && 'fullScreen']}`}>
                You have not been assigned to a team yet.
            </div>
        )
    } else {
        return (
            <div className={`${styles.team_chat} ${styles[isFullScreen && 'fullScreen']}`}>
                <div className={styles.team_chat_title}>{`${team_name} Team Chat`}</div>
                <div className={styles.chat_input_container} onKeyDown={textSubmit} tabIndex='0'>
                    <div className={styles.chat_input_text}>
                        <p className={styles.chat_input_char_limit}>{`characters left`} <span className={`${text.length >= 700 && styles.chat_input_char_limit_counter}`}>{text.length}/700</span> </p>
                    </div>
                    <div className={styles.chat_input_wrapper}>
                        <span>{text}</span>
                        <textarea className={styles.chat_input} value={text} onChange={handleChange} autoComplete="off" name="chat_input" placeholder="Send a message ..." />
                    </div>
                    <button className={styles.chat_input_button} type="submit" onClick={handleClick}>
                        <Icon icon="chat-input" />
                    </button>
                </div>
                <ChatWindow userEmail={email} />
            </div>
        )
    }
}

export default React.memo(TeamChat)