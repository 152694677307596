import React from "react";
import styles from "../styles.module.css";
import Icon from "../../../../../../icon";
import AudioLevelIndicator from "./audio-level-indicator";
import usePlayLocalTrack from "../../../../../../../hooks/use-play-local-track";

const Audio = ({ name, audioTrack, user_raise_hand, raise_hand }) => {
    const [isAudioPlaying, audioRef] = usePlayLocalTrack(audioTrack);

    return (
        <div className={styles.name}>
            <p className={styles.name_text}>{name}</p>
            <AudioLevelIndicator className={styles.icon} {...{ audioTrack, isAudioPlaying }} />
            {(raise_hand && user_raise_hand) && <Icon className={styles.raise_hand_icon} icon="raise-hand-emoji" />}
            <audio ref={audioRef} className={styles.audio} controls={false} onContextMenu={(e) => e.preventDefault()} />
        </div>
    )
};

export default React.memo(Audio);
