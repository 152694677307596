import { useEffect, useState } from 'react';

const useGetUserDevices = () => {
    const [devices, setDevices] = useState({ video: [], audio: [] });
    const [selected, setSelected] = useState({
        video: localStorage.getItem("videoDeviceId"),
        audio: localStorage.getItem("audioDeviceId")
    });

    const promptDevice = async () => {
        return await navigator.mediaDevices.getUserMedia({
            audio: true,
            video: {
                width: { min: 1280, max: 4096 },
                height: { min: 720, max: 2160 },
                frameRate: { min: 30, max: 60 }
            }
        })
    }

    useEffect(() => {
        promptDevice().then((res) => {
            if (res.id) {
                console.log('getUser(platform) w/ id', res)
                navigator.mediaDevices.enumerateDevices().then((devices) => {
                    const video = devices.filter((device) => device.kind === 'videoinput');
                    const audio = devices.filter((device) => device.kind === 'audioinput');
                    setDevices({ video, audio });
                }).catch((err) => {
                    console.log('err (platform enumerateDevices)', err)
                });
            } else {
                console.log('res (platform getUser)', res)
            }
        }).catch((err) => {
            console.log('err (platform getUser)', err)
        })
    }, []);

    const handleDeviceChange = ({ target: { name, value } }) => {
        if (name === "videoInput") {
            localStorage.setItem("videoDeviceId", value)
            setSelected({ ...selected, video: value });
        }
        if (name === "audioInput") {
            localStorage.setItem("audioDeviceId", value)
            setSelected({ ...selected, audio: value });
        }
    }

    useEffect(() => {
        setSelected((selected) => {
            const { video, audio } = devices;
            if (!selected.video && video.length) {
                selected.video = video[0].deviceId;
                localStorage.setItem("videoDeviceId", video[0].deviceId)
            }
            if (!selected.audio && audio.length) {
                selected.audio = audio[0].deviceId;
                localStorage.setItem("audioDeviceId", audio[0].deviceId)
            }
            return selected;
        });
    }, [devices]);

    return [devices, selected, handleDeviceChange]
}

export default useGetUserDevices;