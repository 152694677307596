import { useState, useEffect } from 'react';
import { createLocalTracks } from 'shared-studios-twilio-video';

const usePermissions = () => {
    const [status, setStatus] = useState({ permissions: false, loading: true });

    const handleRetry = () => {
        createLocalTracks({ audio: true, video: true })
            .then((tracks) => {
                tracks.forEach((track) => track.stop());
                setStatus({ permissions: true, loading: false })
            }).catch((error) => {
                console.error(error);
                setStatus({ permissions: false, loading: false })
            })
    }

    useEffect(handleRetry, []);

    return [status, handleRetry];
}

export default usePermissions;