import axios from 'axios';
import store from '../redux/store';
import { baseURL } from '../config.json';

const instance = axios.create({ baseURL });

const createInterceptor = () => {
    const interceptor = instance.interceptors.response.use((response) => response, (error) => {
        const status = error.response ? error.response.status : null;
        if (status === 401) {
            instance.interceptors.response.eject(interceptor);
            return refreshToken().then((access_token) => {
                instance.defaults.headers['authorization'] = access_token;
                error.config.headers['authorization'] = access_token;
                return instance.request(error.config);
            }).catch(() => {
                return Promise.reject(error);
            }).finally(createInterceptor);
        }
        return Promise.reject(error);
    })
}


const refreshToken = () => {
    const state = store.getState();
    const authorization = state.user.refresh_token;
    if (!authorization) return Promise.reject('Unauthorized.');

    return axios.get(`${baseURL}/user/refresh-token`, { headers: { authorization } })
        .then((res) => {
            console.log("TOKEN-REFRESHED-SUCCESSFULLY:", res.data);
            store.dispatch({ type: "TOKEN-REFRESHED-SUCCESSFULLY", payload: res });
            return Promise.resolve(res.data.access_token);
        }).catch((error) => {
            console.log(`TOKEN-REFRESHED-UNSUCCESSFULLY: ${error.response.data.message}`);
            store.dispatch({ type: "TOKEN-REFRESHED-UNSUCCESSFULLY" });
            return Promise.reject('Unauthorized.');
        });
}

createInterceptor();
export default instance;