import React, { useCallback, useEffect } from 'react';
import styles from './styles.module.css';
import Icon from '../../../icon';
import { createLocalVideoTrack } from 'shared-studios-twilio-video';

const VideoControl = ({ videoTrack, setVideoTrack, videoDevice }) => {

    const createVideoTrack = useCallback(() => {
        const constrains = { width: 1280, height: 720, frameRate: 30, deviceId: { exact: videoDevice } }
        createLocalVideoTrack(constrains)
            .then(setVideoTrack).catch((error) => {
                alert(error.message);
                console.error("MeetingControls | 2", error);
            });
    }, [setVideoTrack, videoDevice]);

    const handleVideo = () => {
        console.log("MeetingControls|handleVideo:", videoTrack)
        if (!videoTrack) {
            createVideoTrack()
        } else {
            videoTrack.stop();
            setVideoTrack(null);
        }
    }

    useEffect(createVideoTrack, [createVideoTrack]);

    return (
        <button onClick={handleVideo}>
            <Icon className={styles.icon} icon={videoTrack ? "video-unmute" : "video-mute"} />
        </button>
    )
}

export default React.memo(VideoControl)