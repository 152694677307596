import React from 'react';
import styles from './styles.module.css';
import Icon from '../../../../../icon';
import usePlayRemoteTrack from '../../../../../../hooks/use-play-remote-track';

const Audio = ({ name, country, identity, audioTrack, audioRef }) => {
    const [isPlaying] = usePlayRemoteTrack(audioTrack, audioRef, identity);

    return (
        <div className={styles.name}>
            <Icon className={styles.mic_icon} icon={isPlaying ? "microphone" : "microphone-off"} />{name} {country && `(${country})`}
            <audio ref={audioRef} className={styles.audio} controls={false} />
        </div>
    )
}

export default React.memo(Audio);