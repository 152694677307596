import React from 'react';
import styles from './styles.module.css';
import Icon from '../../../../../../icon';

const VideoControl = ({ videoTrack, handleVideo }) => {
    return (
        <button onClick={handleVideo}>
            <Icon className={styles.icon} icon={videoTrack ? "video-unmute" : "video-mute"} />
        </button>
    )
}

export default React.memo(VideoControl)