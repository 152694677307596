import React, { useEffect, useRef, useState } from 'react';
import Loading from '../loading';
import { useParams } from 'react-router-dom';
import { socket_URL } from '../../config.json';
// import { fetchCurrentMove } from '../actions/moves';
import { onMessage, onError } from '../actions/socket';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

const Socket = ({ children }) => {
    const retry = useRef(0);
    const dispatch = useDispatch();
    const { event_id } = useParams();
    const [error, setError] = useState(null);
    const [connected, setConnected] = useState(false);
    const { email, active } = useSelector(({ user }) => ({ email: user.email, active: user.active }), shallowEqual);

    const connectToSocket = (e) => {
        if (active && email && event_id) {
            if (e) {
                console.log('Creating socket:', e);
                retry.current++;
            }
            else console.log("Creating Socket connection");

            let prevVisibility = document.visibilityState;
            let socket = new WebSocket(`${socket_URL}?email=${email}&event_id=${event_id}`);

            socket.onerror = (e) => {
                console.log("onError:", e);
                dispatch(onError(e));
            }

            socket.onmessage = (e) => {
                console.log("onMessage:", e);
                dispatch(onMessage(e));
            }

            socket.onopen = (e) => {
                console.log("onConnect:", e);
                if (socket.readyState === 1) {
                    retry.current = 0;
                    return setConnected(true);
                }
                if (socket.readyState === 2 || socket.readyState === 3) {
                    alert(`socket Ready Stage is: ${socket.readyState}`)
                    return socket.close(4000, `For some reason socket readyState stage is still: ${socket.readyState}`);
                }
            }

            const handleVisibilityChange = () => {
                const currentVisibility = document.visibilityState
                if (currentVisibility === "visible" && prevVisibility === "hidden") {
                    console.log("Socket | visibility change:", socket);
                    // dispatch(fetchCurrentMove());
                }
                prevVisibility = currentVisibility;
            };

            socket.onclose = (e) => {
                console.log("onClose:", e);
                socket = null;
                document.removeEventListener("visibilitychange", handleVisibilityChange);
                console.log("removed all web socket event  listeners");

                if (e.code === 1000) {
                    // close the socket connection on component unmount
                    console.log("Socket", { code: e.code, reason: e.reason });
                } else if (e.code === 1015 || e.code === 1014) {
                    // 1015 TLS Handshake. 1014 Bad Gateway
                    // if the code is not equal to 1015 or 1014 
                    console.log('Failed to perform a TLS handshake or Bad Gateway:', e)
                    setError("Unknown error occurred while trying to connect. Please refresh the page and try again.");
                    setConnected(false);
                } else {
                    // anything other than 1015 or 1014 then try to reconnect.
                    // if the code is  4000 or 1001 means going away.
                    // if the code is 1006 means Abnormal Closure.
                    if (retry.current <= 10) {
                        connectToSocket(e.code);
                    } else {
                        console.log('Reached maximum limit:', retry.current, e)
                        setError("Unknown error occurred while trying to connect. Please refresh the page and try again.");
                        setConnected(false);
                    }
                }
            }

            document.addEventListener("visibilitychange", handleVisibilityChange);
            console.log("added all web socket event  listeners");
            return () => {
                if (socket && socket.readyState === 1) {
                    socket.close(1000, "Component will unmount.");
                    console.log("Socket will unmount readyState:", socket.readyState);
                }
            }
        }
    }

    useEffect(connectToSocket, [dispatch, event_id, email, active]);

    console.log("Socket connected:", { connected, active });
    if (!active) {
        return children
    } else if (!connected || error) {
        return <Loading message={error} />
    } else {
        return children
    }
};

export default React.memo(Socket);