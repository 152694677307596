import React from 'react';
import styles from './styles.module.css'

const Participant = ({ honorary_title, first_name, last_name, job_title, organization_name }) => {
    return (
        <div className={styles.participant}>
            <p>{honorary_title} {first_name} {last_name}</p>
            {/* <p>{[job_title, organization_name].filter((item) => item).join(", ")}</p> */}
            {job_title.split(", ").map((title) => <p>{title}</p>)}
            <p>{organization_name}</p>
        </div>
    );
}


export default React.memo(Participant)