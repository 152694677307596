import React from 'react';
import Errors from "../errors";
import Socket from '../socket';
import TawkTo from '../tawk-to';
import PublicPage from '../public-page';
import LandingPage from '../landing-page';
import StreamingPage from '../streaming-page';
import { useSelector, shallowEqual } from "react-redux";

const UserValidation = () => {
    const type = useSelector(({ user }) => user.type, shallowEqual);

    if (type === "streamer") {
        return <Socket><Errors /><StreamingPage /></Socket>
    } else if (type === "guest") {
        return <Socket><TawkTo /><Errors /><PublicPage /></Socket>
    } else return <Socket><TawkTo /><Errors /><LandingPage /></Socket>
}

export default React.memo(UserValidation);