import React from 'react';
import styles from './styles.module.css';
import Icon from '../../../../icon';

const SettingMenu = ({ handleChange, videoInputs, audioInputs, videoDevice, audioDevice }) => {
    return (
        <div className={styles.setting_menu}>
            <div className={styles.select_input}>
                <label>Camera</label>
                {videoInputs.length ? <select value={videoDevice} name="videoInput" onChange={handleChange}>
                    {videoInputs.map((device, i) => <option key={i} value={device.deviceId}>{device.label}</option>)}
                </select> : <p>No Camera Device Found.</p>}
                <Icon className={styles.icon} icon="webcam" />
            </div>
            <div className={styles.select_input}>
                <label>Microphone</label>
                {audioInputs.length ? <select value={audioDevice} name="audioInput" onChange={handleChange}>
                    {audioInputs.map((device, i) => <option key={i} value={device.deviceId}>{device.label}</option>)}
                </select> : <p>No Microphone Device Found.</p>}
                <Icon className={styles.icon} icon="microphone-1" />
            </div>
        </div>
    )
}

export default React.memo(SettingMenu)