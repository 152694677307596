import React, { useRef } from 'react';
import styles from './styles.module.css';
import Audio from './audio';
import Video from './video';
// import TracksStatus from './tracks-status';

const RemoteParticipant = (props) => {
    const videoRef = useRef();
    const audioRef = useRef();
    const { name, country, identity, picture, videoTrack, audioTrack, size } = props;

    console.log(`[${identity}] | RemoteParticipant | render:`, { videoTrack });
    return (
        <div className={styles.remote_participant} style={{ ...size }} onContextMenu={(e) => e.preventDefault()}>
            {/* <div className={`${styles.state} ${styles[state]}`} /> */}
            <Audio {...{ country, name, identity, audioTrack, audioRef }} />
            <Video {...{ picture, name, identity, videoTrack, videoRef }} />
            {/* <TracksStatus audioRef={audioRef} videoRef={videoRef} {...props} /> */}
        </div>
    )
}

export default React.memo(RemoteParticipant)