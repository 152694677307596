import axios from '../../axios'

export const updateMove = (data, generateResults, polling, meetingType, lowerHands) => {
    return (dispatch) => {
        const config = { headers: { "Content-Type": "application/json" } }
        if (generateResults) {
            const payload = axios.post(`moves/preference/${data.current_move}`)
                .then(() => axios.patch("moves/current", data, config))
            dispatch({ type: 'UPDATE-MOVE', payload });
        } else if (polling) {
            const payload = axios.delete(`moves/preference/${data.current_move}`)
                .then(() => axios.patch("moves/current", data, config))
            dispatch({ type: 'UPDATE-MOVE', payload });
        } else if (meetingType === "group") {
            const participantsData = {
                participant: true, expert: true, moderator: false,
                show: false, publish: false, audio: false, video: false
            }
            let url = "room/participants";
            if (lowerHands) url = url.concat(`?lowerHands=true`);
            const payload = axios.patch(url, participantsData, config)
                .then(() => axios.patch("moves/current", data, config))
            dispatch({ type: 'UPDATE-MOVE', payload });
        } else {
            return dispatch({ type: 'UPDATE-MOVE', payload: axios.patch("moves/current", data, config) });
        }
    }
}

export const fetchMoves = () => {
    return (dispatch) => {
        dispatch({ type: "FETCH-MOVES", payload: axios.get("moves") });
    }
}
export const fetchCurrentMove = () => {
    return (dispatch) => {
        dispatch({ type: "FETCH-CURRENT-MOVE", payload: axios.get("currentmove") });
    }
}