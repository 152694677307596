import React from 'react';
import styles from './styles.module.css';
import Avatar from '../../../avatar';
import usePlayRemoteTrack from '../../../../../hooks/use-play-remote-track';

const Video = ({ name, identity, picture, videoTrack, videoRef }) => {
    const [isPlaying] = usePlayRemoteTrack(videoTrack, videoRef, identity);

    return (
        <>
            {!isPlaying && <Avatar className={styles.avatar} picture={picture} name={name} />}
            <video ref={videoRef} className={styles[isPlaying ? 'video' : 'hide_video']} controls={false} />
        </>
    )
}

export default React.memo(Video);