import React from "react";
import Icon from "../../../icon";
import styles from "./styles.module.css";
import { useDispatch } from "react-redux";

const Modal = ({ error_message, submitted_preference, loading_message,submitted, loading }) => {
    const dispatch = useDispatch();

    if (loading || loading_message) {
        return (
            <div className={styles.wrapper}>
              <div className={styles.loading_container}>
                <Icon className={styles.loading} icon="loading" />
                <p className={styles.loading_message}>{loading_message}</p>
              </div>
            </div>
        )
    } else if (error_message) {
        return (
            <div className={styles.wrapper}>
                <div className={styles.modal} id="modal">
                    <Icon icon="error-2" />
                    <div className={styles.content}>
                        <span className={styles.error_msg}>{error_message}</span>
                    </div>
                    <button className={styles.toggle_button} onClick={() => dispatch({ type: "CLEAR_ERRORS" })}>
                        Okay
                  </button>
                </div>
            </div>
        )
    } else if (submitted) {
        return (
            <div className={styles.wrapper}>
                <div className={styles.modal} id="modal">
                    <div className={styles.content}>
                        <p>Thank you!</p>
                        <span>
                          <p>Your team has selected:</p>
                          <b>{submitted_preference.map(({ name }) => name).join(", ")}</b>
                        </span>
                        <p>You’ll have your results in a few minutes.</p>
                    </div>
                </div>
            </div>
        )
    } else {
        return null
    }
}

export default React.memo(Modal);