import React from 'react';
import styles from './styles.module.css';
import CloseControl from '../../close-control';

const ScreenShareMenu = ({ handleScreenSharing, toggle }) => {
    return (
        <div className={styles.setting_menu}>
            <CloseControl onClick={() => toggle(false)} />
            <button className={styles.setting_menu_button} onClick={() => handleScreenSharing("screen")}>Share Screen</button>
            <button className={styles.setting_menu_button} onClick={() => handleScreenSharing("grid")}>Share Grid View</button>
        </div>
    )
}

export default React.memo(ScreenShareMenu)