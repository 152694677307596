import React from 'react';
import Icon from '../../../../../../icon';
import styles from './styles.module.css';
import { raiseHand } from '../../../../../../actions/user';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';

const RaiseHand = () => {
    const dispatch = useDispatch()
    const { user_raise_hand, loading } = useSelector(({ user }) => {
        return {
            user_raise_hand: user.raise_hand,
            loading: user.raise_hand_loading
        }
    }, shallowEqual);

    console.log("RaiseHand");
    return (
        <button className={styles[loading && "raise_hand_loading"]} onClick={() => dispatch(raiseHand(!user_raise_hand))} disabled={loading}>
            <Icon className={styles[loading ? 'loading' : 'icon']} icon={loading ? "loading" : "raise-hand"} />
        </button>
    )
}


export default React.memo(RaiseHand);