import React, { useState, useEffect } from 'react';
import styles from './styles.module.css';
import { useDispatch } from 'react-redux';
import { submitPreference } from '../../../../actions/teams'
import usePollingSwap from '../../../../../hooks/use-polling-swap'
import Preference from './preferences'
import SubmitPreferenceButton from './submit-preference-button'
import useCountTo from "../../../../../hooks/use-count-to"


const LeaderPolling = ({ preferences, submitted_preference, submitted, event_time, polling_countdown_time }) => {
    const dispatch = useDispatch();
    const [preferencesList, setPreference] = useState([]);
    const { timeLeft } = useCountTo(event_time, polling_countdown_time, true);
    const [preferredTeam, setPreferredTeams] = useState(Array(10).fill({ blank: true }));
    const swapPreferredTeam = usePollingSwap(preferredTeam, setPreferredTeams, preferencesList, setPreference);

    useEffect(() => {
        if (preferences.length) {
            if (submitted && submitted_preference.length) {
                setPreferredTeams(submitted_preference)
                setPreference(
                    preferences.filter(({ id: team_id }) => {
                        return !submitted_preference.find(({ id }) => team_id === id)
                    })
                )

            } else {
                setPreference(preferences)
                setPreferredTeams(Array(10).fill({ blank: true }))
            }
        }
    }, [preferences, submitted, submitted_preference])


    const submitTeamPreference = () => {
        if (!submitted) {
            dispatch(submitPreference(preferredTeam));
        }
    }

    console.log("Leader Polling", { preferredTeam });
    return (
        <div className={`${styles.rank_teams} `}>
            <SubmitPreferenceButton {...{ timeLeft, submitTeamPreference, submitted }} />
            <p className={styles.preferred_team_title}>Your selection</p>
            <div className={`${styles.preferred_team} `}>
                <div className={styles.preferred_team_left}>
                    {preferredTeam.slice(0, 5).map((team, i) => {
                        return (
                            <div key={i} className={styles.preferred_team_container}>
                                <span className={styles.preferred_team_number}>{i + 1}</span>
                                <Preference {...{ ...team, swapPreferredTeam, disable: (!timeLeft || submitted), index: i }} />
                            </div>
                        )
                    })}
                </div>
                <div className={styles.preferred_team_right}>
                    {preferredTeam.slice(5).map((team, i) => {
                        return (
                            <div key={i} className={styles.preferred_team_container}>
                                <span className={styles.preferred_team_number}>{i + 6}</span>
                                <Preference {...{ ...team, swapPreferredTeam, disable: (!timeLeft || submitted), index: i + 5 }} />
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className={styles.line} />
            <p className={styles.available_team_title}>Available options</p>
            <div className={`${styles.available_team}`}>
                {preferencesList.map((team, i) => <Preference key={i} {...{ ...team, swapPreferredTeam, disable: (!timeLeft || submitted), isAvailable: true, index: i }} />)}
            </div>
        </div>
    )
}

export default React.memo(LeaderPolling);
