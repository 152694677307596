import thunk from 'redux-thunk';
import reducer from "../reducers";
import LogRocket from 'logrocket';
import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from 'redux-devtools-extension';

const createPromise = () => (next) => (action) => {
    if (action.payload instanceof Promise) {
        next({ type: action.type.concat("_PENDING") })
        action.payload.then((res) => {
            return next({ type: action.type.concat("_FULFILLED"), payload: res })
        }).catch((error) => {
            return next({ type: action.type.concat("_REJECTED"), payload: error })
        })
    } else {
        return next(action)
    }
}

const middleware = applyMiddleware(createPromise, thunk, LogRocket.reduxMiddleware())
const DevTools = composeWithDevTools(middleware)

export default createStore(reducer, DevTools)