import React from 'react';
import styles from './styles.module.css'
import Icon from '../icon';

const Loading = ({ message }) => {
    return (
        <div className={styles.loading}>
            {message ? <p className={styles.message}>{message}</p> : <Icon className={styles.icon} icon="loading" />}
        </div>
    )
}
export default React.memo(Loading)