import usePublishTrack from './use-publish-track';
import { createLocalVideoTrack } from 'shared-studios-twilio-video';
import { useState, useEffect, useCallback } from 'react';

const useGetVideoTrack = (lp, deviceId) => {
    // console.log("useGetVideoTrack | deviceId", deviceId);
    // const isPublished = false;
    const [track, setTrack] = useState(null);
    const [isPublished] = usePublishTrack(lp, track, 'video');

    const createTrack = useCallback(() => {
        console.log("useGetVideoTrack | createTrack| deviceId", deviceId);
        const constrains = { width: 1280, height: 720, frameRate: 30 }
        if (deviceId) constrains.deviceId = { exact: deviceId }
        createLocalVideoTrack(constrains).then((track) => {
            setTrack(track);
            console.log("useGetVideoTrack | createTrack", track);
        }).catch((error) => {
            alert(error.message);
            console.error("useGetVideoTrack | createTrack", error);
        });
    }, [deviceId]);

    const handleTrack = () => {
        if (track) {
            track.stop();
            setTrack(null);
            console.log("useGetVideoTrack | handleTrack | stopped");
        } else {
            createTrack();
        }
    }

    useEffect(createTrack, [createTrack]);

    return [track, isPublished, handleTrack]
}

export default useGetVideoTrack;