import React, { useState } from 'react';
import styles from './styles.module.css';
import Icon from '../../icon';

const Accordion = ({ title, iconClassName, children, collapsible }) => {
    const [show, toggle] = useState(true)

    const handelToggle = () => {
        if (collapsible) toggle(!show)
    }

    return (
        <div className={styles.accordion}>
            <p className={styles.title} onClick={handelToggle}>{title}</p>
            {collapsible && <Icon className={`${styles.arrow_icon} ${iconClassName} ${show ? styles.expand : styles.collapse}`} icon="arrow" />}
            {show && children}
        </div>
    )
}


export default React.memo(Accordion)