import React, { useEffect, useRef } from 'react';
import MessageBox from './message-box';
import styles from './styles.module.css';
import { getMessage } from "../../../../actions/chat";
import { useSelector, useDispatch, shallowEqual } from "react-redux"

const ChatWindow = ({ userEmail }) => {
    const dispatch = useDispatch();
    const messageScrollRef = useRef(null);
    const messages = useSelector(({ chat }) => chat.messages, shallowEqual);

    useEffect(() => dispatch(getMessage()), [dispatch]);
    useEffect(() => messageScrollRef.current.scrollTo(0, 0));

    return (
        <div className={styles.chat_window} ref={messageScrollRef}>
            {messages.map(({ user_id, ...message }, i) => {
                return <MessageBox key={i} isUser={(userEmail === user_id)} {...message} />
            }).reverse()}
        </div>
    )
}

export default React.memo(ChatWindow)